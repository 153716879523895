import { Box, Chip, Popover, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { DatesBoxStyled, DayPickerButtonStyled, DisplayDatesStyled } from 'components/day-picker/styles';
import { UPInputLabel } from 'components/input/styles';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import {
  DAYS__SHORT_MONDAY,
  DAYS__SHORT_TUESDAY,
  DAYS__SHORT_WEDNESDAY,
  DAYS__SHORT_THURSDAY,
  DAYS__SHORT_FRIDAY,
  DAYS__SHORT_SATURDAY,
  DAYS__SHORT_SUNDAY,
} from 'translations/constants';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendar.svg';
import { CalendarCellStyled, EmptyCalendarCellStyled } from './styles';
import { UPMultipleCalendarDayPickerProps } from './types';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const UPMultipleCalendarDayPicker = (props: UPMultipleCalendarDayPickerProps) => {
  const { day, calendarDays, onChangeDays, label, required } = props;

  const [t] = useTranslation();

  const weekDays = useMemo(
    () => [
      t(DAYS__SHORT_MONDAY),
      t(DAYS__SHORT_TUESDAY),
      t(DAYS__SHORT_WEDNESDAY),
      t(DAYS__SHORT_THURSDAY),
      t(DAYS__SHORT_FRIDAY),
      t(DAYS__SHORT_SATURDAY),
      t(DAYS__SHORT_SUNDAY),
    ],
    [t],
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const firstDayOfCalendar = moment(day).startOf('month').startOf('week');
  const lastDayOfCalendar = moment(day).endOf('month').endOf('week');

  const mondaysOfTheMonth = [firstDayOfCalendar];
  for (let i = 1; i < 5; i++) {
    mondaysOfTheMonth.push(firstDayOfCalendar.clone().add(i, 'weeks'));
  }
  if (!lastDayOfCalendar.startOf('week').isSame(mondaysOfTheMonth[4])) {
    mondaysOfTheMonth.push(lastDayOfCalendar.startOf('week'));
  }

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box width={3 / 4}>
      <UPInputLabel>
        {label || 'Dates'} {required && '*'}
      </UPInputLabel>
      <DatesBoxStyled>
        <DisplayDatesStyled>
          {calendarDays
            .filter(e => e.checked)
            .map(item => (
              <Chip
                key={item.dayNumber}
                label={
                  <div>
                    <b>{item.dayAbbr}</b> {item.shortDate}
                  </div>
                }
              />
            ))}
        </DisplayDatesStyled>
        <Box>
          <DayPickerButtonStyled
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <CalendarIcon />
          </DayPickerButtonStyled>
        </Box>
      </DatesBoxStyled>
      <Popover
        id="basic-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Table sx={{ borderSpacing: theme.spacing(1 / 2), borderCollapse: 'unset' }}>
          <TableHead>
            <TableRow>
              {weekDays.map(weekDay => (
                <CalendarCellStyled key={weekDay} align="center">
                  {weekDay}
                </CalendarCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {mondaysOfTheMonth.map(monday => (
              <TableRow key={monday.format('DD/MM/YYYY')}>
                {Array.from({ length: 7 }).map((_, index) => {
                  const currentDay = monday.clone().add(index, 'days');
                  const currentCalendarDay = calendarDays.find(e => e.dayNumber === currentDay.date());
                  return currentDay.month() === day.month() ? (
                    <CalendarCellStyled
                      key={currentCalendarDay?.shortDate}
                      align="center"
                      selected={currentCalendarDay?.checked}
                      isDay={currentDay.month() === day.month()}
                      onClick={() => {
                        const prevState = currentCalendarDay?.checked;
                        const newCalendarDays = [...calendarDays];
                        newCalendarDays[currentDay.date() - 1].checked = !prevState;
                        onChangeDays(newCalendarDays);
                      }}
                    >
                      {currentDay.date()}
                    </CalendarCellStyled>
                  ) : (
                    <EmptyCalendarCellStyled />
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Popover>
    </Box>
  );
};

export default UPMultipleCalendarDayPicker;
