import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Chip, IconButton, Popover, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { DayPickerButtonStyled } from 'components/day-picker/styles';
import { UPInputLabel } from 'components/input/styles';
import { NavigationDirection } from 'constants/NavigationDirection';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import {
  DAYS__SHORT_FRIDAY,
  DAYS__SHORT_MONDAY,
  DAYS__SHORT_SATURDAY,
  DAYS__SHORT_SUNDAY,
  DAYS__SHORT_THURSDAY,
  DAYS__SHORT_TUESDAY,
  DAYS__SHORT_WEDNESDAY,
  GENERAL__DATE_RANGE_PICKER,
} from 'translations/constants';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendar.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import {
  CalendarCellStyled,
  CalendarControlsContainer,
  CloseCalendarStyled,
  DatesBoxStyled,
  DisplayDatesStyled,
  EmptyCalendarCellStyled,
} from './styles';
import { UPDateRangePickerProps } from './types';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const UPDateRangePicker: React.FC<UPDateRangePickerProps> = ({ month, startDate, endDate, setDateRange }) => {
  const [t] = useTranslation();
  const [currentMonth, setCurrentMonth] = useState(moment().month(month));
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [hoveredDate, setHoveredDate] = useState<moment.Moment | null>(null);

  const open = Boolean(anchorEl);

  const weekDays = useMemo(
    () => [
      t(DAYS__SHORT_MONDAY),
      t(DAYS__SHORT_TUESDAY),
      t(DAYS__SHORT_WEDNESDAY),
      t(DAYS__SHORT_THURSDAY),
      t(DAYS__SHORT_FRIDAY),
      t(DAYS__SHORT_SATURDAY),
      t(DAYS__SHORT_SUNDAY),
    ],
    [t],
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setHoveredDate(null);
  };

  const handleMonthChange = (direction: NavigationDirection) => {
    setCurrentMonth(currentMonth.clone().add(direction === NavigationDirection.PREVIOUS ? -1 : 1, 'month'));
  };

  const handleDateClick = (date: moment.Moment) => {
    if (!startDate || (startDate && endDate)) {
      setDateRange([date.toDate(), null]);
    } else if (date.isBefore(moment(startDate))) {
      setDateRange([date.toDate(), startDate]);
    } else {
      setDateRange([startDate, date.toDate()]);
    }
    setHoveredDate(null); // Reset hover state on selection
  };

  const isSelected = (date: moment.Moment) =>
    startDate && endDate && date.isBetween(moment(startDate), moment(endDate), 'day', '[]');

  const isHovered = (date: moment.Moment) => {
    if (!startDate || !hoveredDate) return false;
    if (startDate && endDate) return false;
    if (hoveredDate.isBefore(startDate)) {
      return date.isBetween(hoveredDate, startDate, 'day', '[]');
    }
    return date.isBetween(startDate, hoveredDate, 'day', '[]');
  };

  const isInCurrentMonth = (date: moment.Moment) => date.month() === currentMonth.month();

  const startOfMonth = currentMonth.clone().startOf('month').startOf('week');
  const endOfMonth = currentMonth.clone().endOf('month').endOf('week');

  return (
    <Box>
      <UPInputLabel>{t(GENERAL__DATE_RANGE_PICKER)}</UPInputLabel>
      <DatesBoxStyled>
        <DisplayDatesStyled>
          {startDate && <Chip label={moment(startDate).format('MMM DD, YYYY')} />}
          {startDate && endDate && ' - '}
          {endDate && <Chip label={moment(endDate).format('MMM DD, YYYY')} />}
        </DisplayDatesStyled>
        <CalendarControlsContainer>
          <CloseCalendarStyled>
            {startDate && endDate && (
              <CloseIcon fill={theme.palette.secondary.main} onClick={() => setDateRange([null, null])} />
            )}
          </CloseCalendarStyled>
          <DayPickerButtonStyled
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpen}
          >
            <CalendarIcon />
          </DayPickerButtonStyled>
        </CalendarControlsContainer>
      </DatesBoxStyled>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box p={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <IconButton onClick={() => handleMonthChange(NavigationDirection.PREVIOUS)}>
              <KeyboardArrowLeft />
            </IconButton>
            <Box>{currentMonth.format('MMMM YYYY')}</Box>
            <IconButton onClick={() => handleMonthChange(NavigationDirection.NEXT)}>
              <KeyboardArrowRight />
            </IconButton>
          </Box>
          <Table sx={{ borderSpacing: theme.spacing(1 / 2), borderCollapse: 'unset' }}>
            <TableHead>
              <TableRow>
                {weekDays.map(day => (
                  <CalendarCellStyled key={day} align="center">
                    {day}
                  </CalendarCellStyled>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: endOfMonth.diff(startOfMonth, 'weeks') + 1 }).map((_, weekIndex) => {
                const weekStart = startOfMonth.clone().add(weekIndex, 'weeks');
                return (
                  <TableRow key={weekStart.format('WW')}>
                    {Array.from({ length: 7 }).map((__, dayIndex) => {
                      const day = weekStart.clone().add(dayIndex, 'days');
                      return isInCurrentMonth(day) ? (
                        <CalendarCellStyled
                          key={day.format('DD-MM-YYYY')}
                          align="center"
                          selected={isSelected(day)}
                          isDay={isInCurrentMonth(day)}
                          isStartDate={startDate && day.isSame(startDate, 'day')}
                          isEndDate={endDate && day.isSame(endDate, 'day')}
                          hovered={isHovered(day)}
                          onMouseEnter={() => setHoveredDate(day)}
                          onMouseLeave={() => setHoveredDate(null)}
                          onClick={() => handleDateClick(day)}
                        >
                          {day.date()}
                        </CalendarCellStyled>
                      ) : (
                        <EmptyCalendarCellStyled />
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Popover>
    </Box>
  );
};

export default UPDateRangePicker;
