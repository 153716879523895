import { UPDropdown } from 'components/dropdown';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import UPInput from 'components/input';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { getCostCentersDropdownItemsAction } from 'store/actions/company-cost-centers-actions';
import { getWorkingLocationAction } from 'store/actions/contract-actions';
import { costCentersSelector } from 'store/selectors/company-cost-centers-selectors';
import {
  CONTRACTS__COMPANY_LOCATION,
  CONTRACTS__COST_CENTER,
  CONTRACTS__FUNCTION,
  CONTRACTS__WAGE,
  CONTRACTS__WORKING_LOCATION,
  GENERAL__SELECT,
} from 'translations/constants';
import { ContractFormFields } from 'types/contract';
import { getInputErrorText } from 'utils/helpers';
import EditableCostcenter from '../EditableCostCenter/EditableCostcenter';
import { RowContainerForNameAndContractType } from './add/styles';
import {
  CompanyBranchInputStyled,
  CostCenterStyled,
  FunctionStyled,
  WageStyled,
  WorkingLocationStyled,
} from './styles';
import { ContractModalsBottomSectionProps } from './types';

const ContractModalsBottomSection = (props: ContractModalsBottomSectionProps) => {
  const {
    permanent,
    control,
    formState,
    locations,
    functions,
    costCenters,
    isWageVisible,
    locationId,
    companyId,
    setValue,
    getValues,
    costCenterId,
    isEdit,
  } = props;

  const { errors } = formState;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  // get cost centers for selected location if there is one
  useEffect(() => {
    if (locationId) {
      dispatch(
        getCostCentersDropdownItemsAction({
          companyId: +companyId,
          locationId: +locationId,
        }),
      );
    }
  }, [companyId, dispatch, locationId, setValue]);

  // set working location based on selected cost center if there is one
  useEffect(() => {
    if (costCenterId) {
      const workingLocationData = {
        companyId: +companyId,
        costCenterId: +costCenterId,
      };
      dispatch(getWorkingLocationAction(workingLocationData)).then(response => {
        const workingLocation = response.payload.toString();
        setValue(ContractFormFields.WORKING_LOCATION, workingLocation);
      });
    }
  }, [companyId, costCenterId, dispatch, getValues, isEdit, setValue]);

  return (
    <>
      <RowContainerForNameAndContractType>
        <CompanyBranchInputStyled>
          <Controller
            name={ContractFormFields.LOCATION_ID}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <UPDropdown
                value={field.value}
                onChange={field.onChange}
                placeholder={t(GENERAL__SELECT)}
                items={locations}
                label={t(CONTRACTS__COMPANY_LOCATION)}
                required
                error={!!errors.locationId}
                helperText={getInputErrorText(errors, ContractFormFields.LOCATION_ID)}
              />
            )}
          />
        </CompanyBranchInputStyled>
        <CostCenterStyled>
          <Controller
            name={ContractFormFields.COST_CENTER_ID}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <EditableCostcenter
                key={costCenters.length}
                selectedValue={field.value}
                handleChange={field.onChange}
                items={costCenters}
                companyId={companyId}
                label={t(CONTRACTS__COST_CENTER)}
                locationId={locationId}
                required
                error={!!errors.costCenterId}
                hasDefaultValue={!isEdit}
                onClose={() => {
                  dispatch(
                    getCostCentersDropdownItemsAction({
                      companyId: +companyId,
                      locationId: +locationId,
                    }),
                  );
                }}
                helperText={getInputErrorText(errors, ContractFormFields.COST_CENTER_ID)}
              />
            )}
          />
        </CostCenterStyled>
      </RowContainerForNameAndContractType>
      <RowContainerForNameAndContractType>
        {!permanent && (
          <>
            <FunctionStyled>
              <Controller
                name={ContractFormFields.FUNCTION_ID}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPDropdownWithSearch
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t(GENERAL__SELECT)}
                    items={functions}
                    label={t(CONTRACTS__FUNCTION)}
                    required
                    error={!!errors.functionId}
                    hasDefaultValue
                    helperText={getInputErrorText(errors, ContractFormFields.FUNCTION_ID)}
                  />
                )}
              />
            </FunctionStyled>
            {isWageVisible && (
              <WageStyled>
                <Controller
                  name={ContractFormFields.WAGE}
                  control={control}
                  render={({ field }) => (
                    <UPInput
                      value={`€ ${field.value}`}
                      onChange={null}
                      label={t(CONTRACTS__WAGE)}
                      placeholder=""
                      disabled
                    />
                  )}
                />
              </WageStyled>
            )}
          </>
        )}
        <WorkingLocationStyled sx={{ minWidth: permanent ? 'unset' : '280px', width: permanent ? '100%' : '66%' }}>
          <Controller
            name={ContractFormFields.WORKING_LOCATION}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                onChange={null}
                placeholder={t(CONTRACTS__WORKING_LOCATION)}
                label={t(CONTRACTS__WORKING_LOCATION)}
                required
                disabled
              />
            )}
          />
        </WorkingLocationStyled>
      </RowContainerForNameAndContractType>
    </>
  );
};

export default ContractModalsBottomSection;
