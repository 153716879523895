import UPSnackBar from 'components/app-container/snackbar';
import DeleteModal from 'components/delete-modal';
import MessageModal from 'components/message-modal';
import { CompanyRole } from 'constants/CompanyRole';
import { ContractPageSize } from 'constants/ContractPageSize';
import { toNumber } from 'lodash';
import { createDefaultContractFilters } from 'mappers/contracts-mapper';
import {
  generateCardsObject,
  getCurrentTime,
  getISO8601WeekNumber,
  getISO8601Year,
  isAnyContractWeekly,
  sortedContracts,
} from 'modules/contracts/helpers';
import ConfirmContractModal from 'modules/contracts/modals/ConfirmContractModal';
import AddContractModal from 'modules/contracts/modals/add/AddContractModal';
import EditContractModal from 'modules/contracts/modals/edit/EditContractModal';
import { IContractData, IContractToEdit, IContractsCountPerWeek, ICostEstimation } from 'modules/contracts/types';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { getDashboardBlogsAction } from 'store/actions/blog-actions';
import { getCostCentersAction, getCostCentersDropdownItemsAction } from 'store/actions/company-cost-centers-actions';
import { getFunctionsAsDropdownItemsAction } from 'store/actions/company-functions-actions';
import { getLocationsDropdownItemsAction } from 'store/actions/company-locations-actions';
import {
  deleteContractDashboardActionF,
  editContractAction,
  getContractsAction,
  getContractsCountFromPreviousWeeksAction,
  getContractsOfAdjacentWeeksAction,
  getCostEstimationAction,
  validateContractErrorMessageAction,
  validateContractErrorMessageExtraInfoAction,
  validateContractErrorMessageRowAction,
  validateContractSuccessAction,
} from 'store/actions/contract-actions';
import { getEmployeesCountAction, setEmployeeError } from 'store/actions/employee-actions';
import { getDefaultCompanyBreakTimeAction } from 'store/actions/company-actions';
import { defaultCompanyBreakSelector } from 'store/selectors/company-selectors';
import {
  getContractsCountFromPreviousWeeksSelector,
  getContractsSelector,
  getCostEstimationSelector,
} from 'store/selectors/contracts-selector';
import { employeesCountSelector, employeesErrorSelector } from 'store/selectors/employee-selector';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  CONTRACTS__ALREADY_CONFIRMED,
  CONTRACTS__ARE_YOU_SURE,
  CONTRACTS__DEFAULT_ERROR,
  CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING,
  EMPLOYEES__IBAN_ERROR,
  EMPLOYEES__INSS_ERROR,
  EMPLOYEES__PERMANENT_CHANGE_VALIDATION,
  GENERAL__CANCEL_BUTTON,
} from 'translations/constants';
import { IEmployeeTotals } from 'types/employee';
import { addOrSubtractDays, treatAsUTC } from 'utils/helpers';
import AdminDashboard from './admin/AdminDashboard';
import DashboardBlogsList from './DashboardBlogsList';
import DashboardContractsGraph from './DashboardContractsGraph';
import DashboardContractsTile from './DashboardContractsTile';
import DashboardCostEstimationTile from './DashboardCostEstimationTile';
import DashboardEmployee from './DashboardEmployee';
import DashboardEmployeeTile from './DashboardEmployeeTile';
import DashboardShortcutsList from './DashboardShortcutsList';
import { filterContractsByDate, getAdjacentDay } from './helpers';
import {
  BlogContainer,
  BlogsList,
  ContractsContainer,
  ContractsListParent,
  ContractsOverview,
  CostEstimationContainer,
  DashboardContainer,
  DashboardRightSide,
  DashboardTilesContainer,
  EmployeeContainer,
  OldEmployeeContainer,
  ShortcutsContainer,
} from './styles';
import { ContractsFilteredByDays } from './types';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const Dashboard = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const profile = useAppSelector(profileSelector);
  const contracts = useAppSelector(getContractsSelector);
  const contractsCountFromPreviousWeeks = useAppSelector(getContractsCountFromPreviousWeeksSelector);
  const employeesNumbers = useAppSelector(employeesCountSelector);
  const userCompanyId = profile.companyId;
  const userRole = profile.role;
  const employeeError = useAppSelector(employeesErrorSelector);
  const costEstimation = useAppSelector(getCostEstimationSelector);
  const defaultBreakTime = useAppSelector(defaultCompanyBreakSelector);

  const [openAddModal, setOpenAddModal] = useState(false);
  const [location, setLocation] = useState<string>('');
  const [currentWeek, setCurrentWeek] = useState<number>(getISO8601WeekNumber(new Date(Date.now())));
  const [week, setWeek] = useState<Date>(getCurrentTime().date);
  const [filteredContracts, setFilteredContracts] = useState<ContractsFilteredByDays>(null);
  const [currentYear, setCurrentYear] = useState<number>(getISO8601Year(new Date(Date.now())));
  const [contractsNumber, setContractsNumber] = useState<IContractsCountPerWeek[]>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>();
  const [openAlreadyConfirmed, setOpenAlreadyConfirmed] = useState<boolean>();
  const [cardToConfirm, setCardToConfirm] = useState<IContractData>();
  const [costEstimationData, setCostEstimationData] = useState<ICostEstimation[]>(null);
  const [employeesCount, setEmployeesCount] = useState<IEmployeeTotals>(null);
  const [currentDate, setCurrentDate] = useState<Date>(getCurrentTime().date);
  const [selectedContractsIds, setSelectedContractsIds] = useState<number[]>([]);
  const [openConfirmDeleteWeeklyModal, setOpenConfirmDeleteWeeklyModal] = useState<boolean>(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [cardToEdit, setCardToEdit] = useState<IContractToEdit>(null);
  const [openConfirmCloseModal, setOpenConfirmCloseModal] = useState<boolean>(false);
  const [openEmployeeError, setOpenEmployeeError] = useState<boolean>(false);

  const companyId = userCompanyId !== null ? userCompanyId.toString() : undefined;
  const isEmployee = userRole === CompanyRole.EMPLOYEE;
  const hasAccessToNewDashboard = userRole === CompanyRole.COMPANY_MANAGER || userRole === CompanyRole.HR_MANAGER;

  const employeeErrorMessages = useMemo(() => {
    return {
      default: t(CONTRACTS__DEFAULT_ERROR),
      inss: t(EMPLOYEES__INSS_ERROR),
      iban: t(EMPLOYEES__IBAN_ERROR),
      permanent: t(EMPLOYEES__PERMANENT_CHANGE_VALIDATION),
    };
  }, [t]);

  const onClickConfirm = (cardId: number) => {
    const auxCardToConfirm = contracts.find(c => c.id === cardId);
    if (auxCardToConfirm.confirmHours) {
      setOpenAlreadyConfirmed(true);
    } else {
      setCardToConfirm(auxCardToConfirm);
      setOpenConfirmModal(true);
    }
  };

  const makeDeleteRequest = async (ids: number[]) => {
    await dispatch(
      deleteContractDashboardActionF({
        companyId: toNumber(companyId),
        contractsIds: ids,
        year: currentYear,
        weekNumber: currentWeek,
      }),
    );

    setTimeout(() => {
      dispatch(
        getContractsAction({
          companyId: toNumber(companyId),
          filter: createDefaultContractFilters(currentYear, currentWeek, null, null, ContractPageSize.Default),
        }),
      );
    }, 2000);
  };

  const onDeleteCheckedContracts = (ids: number[]) => {
    const newContracts = filteredContracts.currentDayContracts.filter((c: IContractData) => ids.includes(c.id));
    setSelectedContractsIds(ids);
    if (isAnyContractWeekly(newContracts)) setOpenConfirmDeleteWeeklyModal(true);
    else {
      setOpenConfirmDeleteModal(true);
    }
  };

  const makeEditRequest = (contract: IContractToEdit) => {
    dispatch(editContractAction({ companyId: toNumber(companyId), contract }));
    setTimeout(() => {
      dispatch(
        getContractsAction({
          companyId: toNumber(companyId),
          filter: createDefaultContractFilters(
            currentYear,
            currentWeek,
            contract.contractType,
            null,
            ContractPageSize.Default,
          ),
        }),
      );
    }, 5000);
  };

  const onAcceptClosingEditModal = () => {
    setOpenEditModal(false);
    setOpenConfirmCloseModal(false);
    dispatch(validateContractSuccessAction(false));
    dispatch(validateContractErrorMessageAction(''));
    dispatch(validateContractErrorMessageExtraInfoAction(''));
    dispatch(validateContractErrorMessageRowAction(null));
  };

  const onClickEditCard = (id: number, isDaily: boolean, parentId?: string) => {
    if (isDaily) {
      const card = contracts.find((c: IContractData) => c.id === id);
      const specialCard = {
        contractId: card.contractId,
        employee: card?.employee,
        error: false,
        contractType: card?.contractType,
        functionId: card?.functionId,
        costCenterId: card?.costCenterId,
        locationId: card?.locationId,
        workingLocation: card?.workingLocation,
        companyId: card?.companyId,
        dates: [
          {
            id: card.id,
            date: card.date,
            startHour: card.startHour,
            endHour: card.endHour,
            startBreak: card.startBreak,
            endBreak: card.endBreak,
          },
        ],
        errorMessage: card.errorMessage ? card.errorMessage : null,
      };
      setCardToEdit(specialCard);
      setOpenEditModal(true);
    } else {
      const weeklyArray = generateCardsObject(parentId, contracts);
      setCardToEdit(weeklyArray);
      setOpenEditModal(true);
    }
  };

  useEffect(() => {
    if (employeeError) setOpenEmployeeError(true);
  }, [employeeError]);

  useEffect(() => {
    const auxContracts: ContractsFilteredByDays = {
      previousDayContracts: sortedContracts(filterContractsByDate(contracts, getAdjacentDay(currentDate, -1))),
      currentDayContracts: sortedContracts(filterContractsByDate(contracts, currentDate)),
      nextDayContracts: sortedContracts(filterContractsByDate(contracts, getAdjacentDay(currentDate, 1))),
    };
    setFilteredContracts(auxContracts);
  }, [contracts, currentDate]);

  useEffect(() => {
    setCurrentYear(getISO8601Year(currentDate));
    setCurrentWeek(getISO8601WeekNumber(currentDate));
  }, [currentDate]);

  useEffect(() => {
    if (contractsCountFromPreviousWeeks) {
      setContractsNumber(contractsCountFromPreviousWeeks);
    }
  }, [contractsCountFromPreviousWeeks, contractsNumber]);

  useEffect(() => {
    if (costEstimation && costEstimationData === null) {
      setCostEstimationData(costEstimation);
    }
  }, [costEstimation, costEstimationData]);

  useEffect(() => {
    if (employeesNumbers && employeesCount === null) {
      setEmployeesCount(employeesNumbers);
    }
  }, [employeesCount, employeesNumbers]);

  const onChangeWeek = useCallback((timePeriod: string, date: Date) => {
    const timeMultiplier = timePeriod === 'past' ? -1 : 1;
    const newCheckedDays: { date: Date; checked: boolean }[] = [];
    for (let counter = 1; counter <= 7; ++counter) {
      const day = {
        date: addOrSubtractDays(counter * timeMultiplier, date),
        checked: false,
      };
      if (timePeriod === 'past') {
        newCheckedDays.unshift(day);
      } else {
        newCheckedDays.push(day);
      }
    }
    setCurrentWeek(getISO8601WeekNumber(treatAsUTC(newCheckedDays[0].date)));
    setWeek(treatAsUTC(newCheckedDays[0].date));
  }, []);

  useEffect(() => {
    if (companyId && !isEmployee) {
      dispatch(getCostCentersDropdownItemsAction({ companyId: toNumber(companyId) }));
      dispatch(getCostCentersAction(+companyId));
      dispatch(getFunctionsAsDropdownItemsAction(toNumber(companyId)));
      dispatch(getLocationsDropdownItemsAction({ companyId: toNumber(companyId) }));
      dispatch(getDefaultCompanyBreakTimeAction(toNumber(companyId)));
      dispatch(
        getContractsOfAdjacentWeeksAction({
          companyId: toNumber(companyId),
          year: currentYear,
          weekNumber: currentWeek,
        }),
      );
      dispatch(
        getContractsCountFromPreviousWeeksAction({
          companyId: toNumber(companyId),
          year: currentYear,
          weekNumber: currentWeek,
          locationId: toNumber(location),
        }),
      );
      dispatch(
        getEmployeesCountAction({
          companyId: toNumber(companyId),
          weekNumber: currentWeek,
        }),
      );
      dispatch(getDashboardBlogsAction());
      dispatch(
        getCostEstimationAction({
          companyId: toNumber(companyId),
          weekNumber: currentWeek,
          year: currentYear,
        }),
      );
    }
    if (isEmployee) {
      dispatch(
        getContractsOfAdjacentWeeksAction({
          companyId: 0,
          year: currentYear,
          weekNumber: currentWeek,
        }),
      );
      dispatch(
        getContractsCountFromPreviousWeeksAction({
          year: currentYear,
          weekNumber: currentWeek,
          locationId: 0,
        }),
      );
      dispatch(getCostCentersAction(+companyId));
      dispatch(getCostCentersDropdownItemsAction({ companyId: +companyId }));

      dispatch(getDashboardBlogsAction());
    }
  }, [companyId, currentYear, currentWeek, dispatch, isEmployee, location]);

  const onConfirmContract = () => {
    setTimeout(() => {
      dispatch(
        getContractsAction({
          companyId: toNumber(companyId),
          filter: createDefaultContractFilters(
            currentYear,
            currentWeek,
            cardToConfirm.contractType,
            null,
            ContractPageSize.Default,
          ),
        }),
      );
    }, 5000);
  };

  if (isEmployee || userCompanyId) {
    return (
      <DashboardContainer>
        <ContractsContainer>
          <ContractsOverview>
            <DashboardContractsGraph
              openAddNewContractModal={() => setOpenAddModal(true)}
              contractsNumber={contractsNumber}
              isEmployee={isEmployee}
            />
          </ContractsOverview>
          <ContractsListParent>
            <DashboardContractsTile
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              onDeleteCheckedContracts={onDeleteCheckedContracts}
              onClickEditCard={onClickEditCard}
              filteredContracts={filteredContracts}
              location={location}
              setLocation={setLocation}
              isEmployee={isEmployee}
              onClickConfirm={onClickConfirm}
              year={currentYear}
              weekNumber={currentWeek}
            />
          </ContractsListParent>
        </ContractsContainer>
        <DashboardRightSide>
          {userCompanyId && hasAccessToNewDashboard ? (
            <DashboardTilesContainer>
              <EmployeeContainer>
                <DashboardEmployeeTile
                  employeesInvited={employeesCount ? employeesCount.invited : 0}
                  employeesReady={employeesCount ? employeesCount.ready : 0}
                  employeesDeployed={employeesCount ? employeesCount.deployed : 0}
                  companyId={userCompanyId}
                />
              </EmployeeContainer>
              <CostEstimationContainer>
                <DashboardCostEstimationTile costEstimationData={costEstimationData} />
              </CostEstimationContainer>
            </DashboardTilesContainer>
          ) : (
            userCompanyId &&
            !isEmployee && (
              <OldEmployeeContainer>
                <DashboardEmployee
                  employeesInvited={employeesCount ? employeesCount.invited : 0}
                  employeesReady={employeesCount ? employeesCount.ready : 0}
                  employeesDeployed={employeesCount ? employeesCount.deployed : 0}
                  companyId={userCompanyId.toString()}
                />
              </OldEmployeeContainer>
            )
          )}

          {isEmployee && (
            <ShortcutsContainer>
              <DashboardShortcutsList />
            </ShortcutsContainer>
          )}
          <BlogContainer sx={{ width: '100%' }}>
            <BlogsList>
              <DashboardBlogsList />
            </BlogsList>
          </BlogContainer>
        </DashboardRightSide>
        {openAddModal && (
          <AddContractModal
            onClose={() => {
              setOpenAddModal(false);
            }}
            onSuccess={() => {
              // made the post req
            }}
            companyId={companyId}
            week={currentWeek}
            weekDate={week}
            defaultBreakTime={defaultBreakTime}
          />
        )}
        {openConfirmDeleteWeeklyModal && (
          <DeleteModal
            open={openConfirmDeleteWeeklyModal}
            content={t(CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING)}
            onClose={() => {
              setOpenConfirmDeleteWeeklyModal(false);
            }}
            onDelete={() => {
              makeDeleteRequest(selectedContractsIds);
            }}
          />
        )}

        {openConfirmDeleteModal && (
          <DeleteModal
            open={openConfirmDeleteModal}
            content={t(CONTRACTS__ARE_YOU_SURE)}
            onClose={() => {
              setOpenConfirmDeleteModal(false);
            }}
            onDelete={() => {
              makeDeleteRequest(selectedContractsIds);
            }}
          />
        )}
        {openEditModal && (
          <EditContractModal
            onClose={() => {
              if (isEmployee) setOpenEditModal(false);
              else setOpenConfirmCloseModal(true);
            }}
            onSuccess={(contract: any) => {
              makeEditRequest(contract);
              setOpenEditModal(false);
              setOpenConfirmCloseModal(false);
            }}
            companyId={`${cardToEdit.companyId}`}
            week={currentWeek}
            contractToEdit={cardToEdit}
            weekDate={cardToEdit?.date}
            isContractSlot={false}
            defaultBreakTime={defaultBreakTime}
          />
        )}

        {openConfirmModal && (
          <ConfirmContractModal
            open={openConfirmModal}
            onClose={() => {
              setOpenConfirmModal(false);
              dispatch(
                getContractsAction({
                  companyId: toNumber(companyId),
                  filter: createDefaultContractFilters(
                    currentYear,
                    currentWeek,
                    cardToConfirm.contractType,
                    null,
                    ContractPageSize.Default,
                  ),
                }),
              );
            }}
            companyId={companyId}
            contractDateId={cardToConfirm.id}
            contractError={cardToConfirm.errorMessage ? cardToConfirm.errorMessage : null}
            onSuccess={() => {
              onConfirmContract();
            }}
          />
        )}

        {openAlreadyConfirmed && (
          <MessageModal
            message={t(CONTRACTS__ALREADY_CONFIRMED)}
            buttonText={t(GENERAL__CANCEL_BUTTON)}
            open={openAlreadyConfirmed}
            onClose={() => setOpenAlreadyConfirmed(false)}
          />
        )}

        {openConfirmCloseModal && (
          <DeleteModal
            open={openConfirmCloseModal}
            content={t(CONTRACTS__ARE_YOU_SURE)}
            onClose={() => {
              setOpenConfirmCloseModal(false);
            }}
            onDelete={() => {
              onAcceptClosingEditModal();
            }}
          />
        )}
        {employeeError && (
          <UPSnackBar
            vertical="bottom"
            horizontal="right"
            open={openEmployeeError}
            content={
              employeeError === 'inss' ||
              employeeError === 'email' ||
              employeeError === 'iban' ||
              employeeError === 'default' ||
              employeeError === 'permanent'
                ? employeeErrorMessages[employeeError]
                : t(employeeError)
            }
            type="error"
            onClose={() => {
              setOpenEmployeeError(false);
              dispatch(setEmployeeError(null));
            }}
            index={0}
          />
        )}
      </DashboardContainer>
    );
  }

  return <AdminDashboard />;
};

export default Dashboard;
