import { Box, styled, TableCell } from '@mui/material';

export const DatesBoxStyled = styled(Box)<{ error?: boolean }>(({ theme, error }) => ({
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: '4px',
  height: '48px',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  ...(error && {
    borderColor: theme.palette.error.main,
  }),
}));

export const DisplayDatesStyled = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  float: 'left',
  padding: '6px 2px',
  maxWidth: '240px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  '& .MuiChip-root': {
    fontSize: '12px',
    borderRadius: '4px',
    margin: '1px',
    backgroundColor: theme.palette.info.main,
    '& .MuiChip-label': {
      padding: '0px 5px',
    },
  },
}));

export const CalendarCellStyled = styled(TableCell)<{
  selected?: boolean;
  isDay?: boolean;
  hovered?: boolean;
  isStartDate?: boolean;
  isEndDate?: boolean;
}>(({ theme, selected, isDay, hovered, isStartDate, isEndDate }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
  borderRadius: (() => {
    if (hovered) return '0';
    if (isStartDate && isEndDate) return '25%';
    if (isStartDate) return '25% 0 0 25%';
    if (isEndDate) return '0 25% 25% 0';
    return '0';
  })(),
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderBottom: 'none',
  cursor: isDay ? 'pointer' : 'default',
  backgroundColor: (() => {
    if (hovered) return '#71b790';
    if (isDay) {
      return selected ? theme.palette.primary.main : theme.palette.primary.light;
    }
    return 'transparent';
  })(),
  color: selected && !hovered ? 'white' : theme.palette.secondary.dark,
  fontWeight: 'bold',
}));

export const EmptyCalendarCellStyled = styled(TableCell)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderBottom: 'none',
}));

export const CloseCalendarStyled = styled(Box)({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const CalendarControlsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
