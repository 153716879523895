import { Box } from '@mui/material';
import { CompanyRole } from 'constants/CompanyRole';
import { NavigationDirection } from 'constants/NavigationDirection';
import { toNumber } from 'lodash';
import moment from 'moment';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { getAvailabilitiesAction, updateAvailabilitiesAction } from 'store/actions/availabilities-actions';
import { availabilitySelector } from 'store/selectors/availabilities-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import { IAvailability, ICalendarDay } from 'types/availabilities';
import { generateFullMonthDays, getNumberOfNewChanges } from '../utils/helpers';
import AvailabilitiesGreenBar from './green-bar';
import FillAvailabilityModal from './modal';
import {
  AvailabilitiesContainer,
  AvailabilitiesNavigationContainer,
  ScaledUpLeftArrow,
  ScaledUpRightArrow,
} from './styles';
import AvailabilitiesTable from './table';
import { AvailabilitiesContextProps } from './types';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

export const AvailabilitiesContext = createContext<AvailabilitiesContextProps>({
  currentMonth: 0,
  currentYear: 0,
  openAvailabilityFillModal: null,
  availability: null,
});

const Availabilities = (): JSX.Element => {
  const { companyId } = useParams();
  const dispatch = useAppDispatch();

  const profile = useAppSelector(profileSelector);
  const availability = useAppSelector(availabilitySelector);

  const [currentMonthView, setCurrentMonthView] = useState<number>(moment().month());
  const [fillModalOpen, setFillModalOpen] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<moment.Moment>(moment());
  const [firstDayOfCalendar, setFirstDayOfCalendar] = useState<moment.Moment>(moment());
  const [calendarDays, setCalendarDays] = useState<ICalendarDay[]>([]);
  const [currentAvailability, setCurrentAvailability] = useState<IAvailability>(availability);
  const [year, setYear] = useState<number>(moment().year());

  const isSubmissionNeeded = useMemo(() => {
    if (!currentAvailability?.isSubmitted) {
      return true;
    }
    return false;
  }, [currentAvailability]);

  const numberOfChanges = useMemo(() => {
    if (!availability?.days || !currentAvailability?.days) {
      return 0;
    }
    return getNumberOfNewChanges(availability, currentAvailability);
  }, [availability, currentAvailability]);

  const profileId = profile.id;
  const isEmployee = profile.role === CompanyRole.EMPLOYEE;

  const changeMonth = (navigationDirection: NavigationDirection) => {
    const newMonth = navigationDirection === NavigationDirection.NEXT ? currentMonthView + 1 : currentMonthView - 1;
    if (newMonth < 0) {
      setCurrentMonthView(11);
      setYear(year - 1);
      return;
    }
    if (newMonth > 11) {
      setCurrentMonthView(0);
      setYear(year + 1);
      return;
    }
    setCurrentMonthView(newMonth);
  };

  const changeDayOnMobile = (day: moment.Moment, direction: number) => {
    if (direction === 1) {
      setSelectedDay(day.clone().add(1, 'days'));
    } else {
      setSelectedDay(day.clone().subtract(1, 'days'));
    }
  };

  const onSubmitAvailability = () => {
    dispatch(
      updateAvailabilitiesAction({
        availability: currentAvailability,
      }),
    );
  };

  const openAvailabilityFillModal = useCallback(
    (day: moment.Moment) => {
      setFillModalOpen(true);
      setFirstDayOfCalendar(moment().month(currentMonthView).year(year).startOf('month').startOf('week'));
      setSelectedDay(day);
      setCalendarDays(prevState => {
        return prevState.map(calendarDay => {
          if (calendarDay.date.isSame(day, 'day')) {
            return { ...calendarDay, checked: true };
          }
          return { ...calendarDay, checked: false };
        });
      });
    },
    [currentMonthView, year],
  );

  useEffect(() => {
    setCalendarDays(generateFullMonthDays(currentMonthView, year));
    setCurrentAvailability(availability);
  }, [currentMonthView, availability, year]);

  useEffect(() => {
    dispatch(
      getAvailabilitiesAction({
        month: currentMonthView + 1,
        year,
        employeeId: 0,
        companyId: toNumber(companyId),
        userId: isEmployee ? profileId : 0,
      }),
    );
  }, [dispatch, isEmployee, year, currentMonthView, companyId, profileId]);

  const contextValue = useMemo((): AvailabilitiesContextProps => {
    return {
      currentMonth: currentMonthView,
      currentYear: year,
      openAvailabilityFillModal: (day: moment.Moment) => openAvailabilityFillModal(day),
      availability: currentAvailability,
    };
  }, [currentMonthView, currentAvailability, openAvailabilityFillModal, year]);

  return (
    <Box>
      <AvailabilitiesContainer>
        <AvailabilitiesNavigationContainer>
          <ScaledUpLeftArrow onClick={() => changeMonth(NavigationDirection.PREVIOUS)} />
        </AvailabilitiesNavigationContainer>
        <AvailabilitiesContext.Provider value={contextValue}>
          <AvailabilitiesTable onChangeMonth={changeMonth} />
        </AvailabilitiesContext.Provider>
        <AvailabilitiesNavigationContainer>
          <ScaledUpRightArrow onClick={() => changeMonth(NavigationDirection.NEXT)} />
        </AvailabilitiesNavigationContainer>

        {fillModalOpen && (
          <FillAvailabilityModal
            day={selectedDay}
            onClose={() => setFillModalOpen(false)}
            calendarDays={calendarDays}
            onChangeDays={setCalendarDays}
            changeDayOnMobile={changeDayOnMobile}
            onChangeAvailability={setCurrentAvailability}
            availability={currentAvailability}
          />
        )}
      </AvailabilitiesContainer>
      {isSubmissionNeeded && (
        <AvailabilitiesGreenBar
          onSubmit={onSubmitAvailability}
          isSubmissionNeeded={isSubmissionNeeded}
          numberOfChanges={numberOfChanges}
        />
      )}
    </Box>
  );
};

export default Availabilities;
