import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box } from '@mui/material';
import { UPDropdown } from 'components/dropdown';
import { toNumber } from 'lodash';
import ContractCard from 'modules/contracts/grid/ContractCard/ContractCard';
import { CardsDivider } from 'modules/contracts/styles';
import { IContractData } from 'modules/contracts/types';
import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { filterContractsAction } from 'store/actions/contract-actions';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import {
  GENERAL__SELECT,
  WEEKDAY__FRIDAY_ABBREVIATION,
  WEEKDAY__MONDAY_ABBREVIATION,
  WEEKDAY__SATURDAY_ABBREVIATION,
  WEEKDAY__SUNDAY_ABBREVIATION,
  WEEKDAY__THURSDAY_ABBREVIATION,
  WEEKDAY__TUESDAY_ABBREVIATION,
  WEEKDAY__WEDNESDAY_ABBREVIATION,
} from 'translations/constants';
import { generateDate } from './helpers';
import {
  ContractsSideLeftColumnStyled,
  ContractsSideRightColumnStyled,
  DashboardContractCardsContainer,
  DashboardContractsDateContainer,
  DashboardContractsDayOfWeek,
  DashboardContractsTileStyled,
  DashboardLocationDropdownContainer,
  GridColumnStyled,
  LeftGradientColumnStyled,
  NumberOfItems,
  RightGradientColumnStyled,
  SideGridColumnStyled,
} from './styles';
import { DashboardContractsTileProps } from './types';

const DashboardContractsTile = (props: DashboardContractsTileProps) => {
  const {
    currentDate,
    setCurrentDate,
    filteredContracts,
    onClickEditCard,
    onDeleteCheckedContracts,
    location,
    setLocation,
    isEmployee,
    onClickConfirm,
    year,
    weekNumber,
  } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const locationsList = useAppSelector(locationsDropdownItemsSelector);

  const weekDaysNames = [
    t(WEEKDAY__MONDAY_ABBREVIATION),
    t(WEEKDAY__TUESDAY_ABBREVIATION),
    t(WEEKDAY__WEDNESDAY_ABBREVIATION),
    t(WEEKDAY__THURSDAY_ABBREVIATION),
    t(WEEKDAY__FRIDAY_ABBREVIATION),
    t(WEEKDAY__SATURDAY_ABBREVIATION),
    t(WEEKDAY__SUNDAY_ABBREVIATION),
  ];

  const changeDay = (change: number) => {
    const nextDay = new Date(currentDate);
    nextDay.setDate(nextDay.getDate() + change);
    setCurrentDate(nextDay);
  };

  const getFirstContractId = () => {
    const todayDate = new Date().getDate();
    const todayHour = new Date().getHours();

    const dateOfCurrentDayContracts = new Date(filteredContracts?.currentDayContracts[0]?.date).getDate();

    if (dateOfCurrentDayContracts === todayDate) {
      const firstActiveContract = filteredContracts?.currentDayContracts?.find(contract => {
        const contractStartHour = toNumber(contract.startHour.split(':')[0]);
        if (contractStartHour > todayHour) {
          return true;
        }
        return false;
      });

      if (firstActiveContract) {
        return firstActiveContract.id;
      }
    }
    return null;
  };

  const getContract = useCallback(
    (firstActiveContractId, contract) => {
      return (
        <Fragment key={contract.id}>
          {firstActiveContractId === contract.id && <CardsDivider />}
          <ContractCard
            onClickConfirm={onClickConfirm}
            card={contract}
            isCopyEnabled={false}
            onDelete={onDeleteCheckedContracts}
            onEdit={onClickEditCard}
            year={year}
            week={weekNumber}
          />
        </Fragment>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDeleteCheckedContracts, onClickEditCard, onClickConfirm],
  );

  const findRightTranslation = (dayAbr: string) => {
    switch (dayAbr) {
      case 'Mon':
        return weekDaysNames[0];
      case 'Tue':
        return weekDaysNames[1];
      case 'Wed':
        return weekDaysNames[2];
      case 'Thu':
        return weekDaysNames[3];
      case 'Fri':
        return weekDaysNames[4];
      case 'Sat':
        return weekDaysNames[5];
      default:
        return weekDaysNames[6];
    }
  };

  return (
    <DashboardContractsTileStyled>
      <DashboardContractsDateContainer>
        <Box>
          <KeyboardArrowLeftIcon
            onClick={() => {
              changeDay(-1);
            }}
            sx={{ cursor: 'pointer' }}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <DashboardContractsDayOfWeek>
            {findRightTranslation(generateDate(new Date(currentDate).toDateString(), 'start'))}
          </DashboardContractsDayOfWeek>
          <Box>{generateDate(new Date(currentDate).toDateString(), 'end')}</Box>
          <NumberOfItems>{filteredContracts?.currentDayContracts?.length}</NumberOfItems>
        </Box>
        <Box>
          <KeyboardArrowRightIcon
            onClick={() => {
              changeDay(1);
            }}
            sx={{ cursor: 'pointer' }}
          />
        </Box>
      </DashboardContractsDateContainer>
      {!isEmployee && (
        <DashboardLocationDropdownContainer>
          <UPDropdown
            items={locationsList}
            value={location}
            onChange={e => {
              setLocation(e.target.value);
              dispatch(
                filterContractsAction({
                  filterType: 'locationId',
                  value: e.target.value || null,
                }),
              );
            }}
            label=""
            placeholder={t(GENERAL__SELECT)}
            isFilter
          />
        </DashboardLocationDropdownContainer>
      )}
      <DashboardContractCardsContainer>
        {filteredContracts ? (
          <>
            <ContractsSideLeftColumnStyled>
              <LeftGradientColumnStyled
                onClick={() => {
                  changeDay(-1);
                }}
              />
              <SideGridColumnStyled>
                <>
                  {filteredContracts.previousDayContracts.map((c: IContractData) =>
                    getContract(getFirstContractId(), c),
                  )}
                </>
              </SideGridColumnStyled>
            </ContractsSideLeftColumnStyled>
            <GridColumnStyled>
              {filteredContracts.currentDayContracts.map((c: IContractData) => getContract(getFirstContractId(), c))}
            </GridColumnStyled>
            <ContractsSideRightColumnStyled>
              <RightGradientColumnStyled
                onClick={() => {
                  changeDay(1);
                }}
              />
              <SideGridColumnStyled>
                <>
                  {filteredContracts.nextDayContracts.map((c: IContractData) => getContract(getFirstContractId(), c))}
                </>
              </SideGridColumnStyled>
            </ContractsSideRightColumnStyled>
          </>
        ) : (
          <Box />
        )}
      </DashboardContractCardsContainer>
    </DashboardContractsTileStyled>
  );
};

export default DashboardContractsTile;
