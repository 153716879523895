import { UPDropdownItem } from 'components/dropdown';
import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { IContractFormFields } from 'types/contract';

const usePrefilledModalData = (
  locations: UPDropdownItem[],
  functions: UPDropdownItem[],
  costCenters: UPDropdownItem[],
  setValue: UseFormSetValue<IContractFormFields>,
) => {
  useEffect(() => {
    if (locations.length === 1) {
      setValue('locationId', locations[0].value);
    }
    if (functions.length === 1) {
      setValue('functionId', functions[0].value);
    }
    if (costCenters.length === 1) {
      setValue('costCenterId', costCenters[0].value);
    }
  }, [locations, functions, costCenters, setValue]);
};

export default usePrefilledModalData;
