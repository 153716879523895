import { UPDropdownItem } from 'components/dropdown';
import { CompanyAvailabilityFilterType } from 'constants/CompanyAvailabilityFilterType';
import { ContractsFilterType } from 'constants/ContractsFilterType';
import FunctionType from 'constants/FunctionType';
import { ModeOfTransport } from 'constants/ModeOfTransport';
import { PaymentMethod } from 'constants/PaymentMethod';
import { ReasonForEmployment } from 'constants/ReasonForEmployment';
import { ReasonForNoShow } from 'constants/ReasonForNoShow';
import { Role } from 'constants/Role';
import useFunctionTypesTranslation from 'hooks/useFunctionTypesTranslation';
import useModesOfTransportTranslation from 'hooks/useModesOfTransportTranslation';
import usePaymentMethodsTranslation from 'hooks/usePaymentMethodsTranslation';
import useReasonForEmploymentTranslation from 'hooks/useReasonForEmploymentTranslation';
import useReasonForNoShowTranslation from 'hooks/useReasonForNoShowTranslation';
import useRolesTranslations from 'hooks/useRolesTranslations';
import i18next from 'i18next';
import { FieldError } from 'react-hook-form';
import {
  DOCUMENTS__INVALID_EXPIRATION_DATE,
  EMPLOYEE__COMPANY_LOCATION_NO_COMMON,
  EMPLOYEES__SAME_EMAIL_AS_OTHER_USER,
  LOGIN__PASSWORD_MIN_LENGTH_ERROR,
  PASSWORD_POLICY__PASSWORD_POLICY,
  VALIDATION__CHARACTERS,
  VALIDATION__DIGITS,
  VALIDATION__INVALID_FIELD,
  VALIDATION__INVALID_FORMAT,
  VALIDATION__INVALID_INSS,
  VALIDATION__INVALID_NUMBER,
  VALIDATION__IS_REQUIRED,
  VALIDATION__MAX_LENGTH,
  VALIDATION__MIN_LENGTH,
  VALIDATION__PASSWORDS_NOT_MATCH,
  WEEKDAY__FRIDAY_ABBREVIATION,
  WEEKDAY__MONDAY_ABBREVIATION,
  WEEKDAY__SATURDAY_ABBREVIATION,
  WEEKDAY__SUNDAY_ABBREVIATION,
  WEEKDAY__THURSDAY_ABBREVIATION,
  WEEKDAY__TUESDAY_ABBREVIATION,
  WEEKDAY__WEDNESDAY_ABBREVIATION,
} from 'translations/constants';
import Address from 'types/common/IAddress';

export function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function getInputErrorText(
  errors: any,
  field: string,
  property?: string,
  maxLength?: number,
  invalidFormat?: boolean,
  minLength?: number,
  isNumber?: boolean,
  isPassword?: boolean,
): string {
  const fieldError: FieldError = errors[field as keyof typeof errors] as FieldError;
  if (fieldError !== undefined) {
    if (property !== undefined) {
      const propertyFieldError: FieldError = fieldError[property as keyof typeof fieldError] as FieldError;
      if (propertyFieldError !== undefined) {
        if (propertyFieldError.type === 'required') {
          return i18next.t(VALIDATION__IS_REQUIRED);
        }
        if (propertyFieldError.type === 'maxLength') {
          return i18next.t(VALIDATION__MAX_LENGTH).concat(` ${maxLength} `, i18next.t(VALIDATION__CHARACTERS), '.');
        }
        if (propertyFieldError.type === 'min') {
          return i18next.t(VALIDATION__INVALID_NUMBER);
        }
      }
    } else {
      if (fieldError.type === 'required') {
        return i18next.t(VALIDATION__IS_REQUIRED);
      }
      if (fieldError.type === 'maxLength') {
        if (isNumber === null || isNumber === undefined || isNumber === false) {
          return i18next.t(VALIDATION__MAX_LENGTH).concat(` ${maxLength} `, i18next.t(VALIDATION__CHARACTERS), '.');
        }
        return i18next.t(VALIDATION__MAX_LENGTH).concat(` ${maxLength} `, i18next.t(VALIDATION__DIGITS), '.');
      }
    }
    if (fieldError.type === 'pattern') {
      return i18next.t(VALIDATION__INVALID_FORMAT);
    }
    if (fieldError.type === 'match') {
      return i18next.t(VALIDATION__PASSWORDS_NOT_MATCH);
    }
    if (fieldError.type === 'minLength' && !isPassword) {
      return i18next.t(VALIDATION__MIN_LENGTH).concat(` ${minLength} `, i18next.t(VALIDATION__CHARACTERS), '.');
    }
    if (fieldError.type === 'invalidINSS') {
      return i18next.t(VALIDATION__INVALID_INSS);
    }
    if (fieldError.type === 'noCompanyLocation') {
      return i18next.t(EMPLOYEE__COMPANY_LOCATION_NO_COMMON);
    }

    if (fieldError.type === 'policy') {
      return i18next.t(PASSWORD_POLICY__PASSWORD_POLICY);
    }

    if (fieldError.type === 'adminEmployeePolicy') {
      return i18next.t(LOGIN__PASSWORD_MIN_LENGTH_ERROR);
    }

    if (isPassword) {
      return i18next.t(LOGIN__PASSWORD_MIN_LENGTH_ERROR);
    }

    if (fieldError.type === 'idPeriodInvalid') {
      return i18next.t(DOCUMENTS__INVALID_EXPIRATION_DATE);
    }
  }
  if (property === undefined && invalidFormat != null && invalidFormat) {
    return i18next.t(VALIDATION__INVALID_FIELD);
  }
  if (field === 'idPeriodEnd' && errors['idPeriodInvalid' as keyof typeof errors]) {
    return i18next.t(DOCUMENTS__INVALID_EXPIRATION_DATE);
  }
  if (property === 'emailSameAsOtherUser') {
    return i18next.t(EMPLOYEES__SAME_EMAIL_AS_OTHER_USER);
  }
  return '';
}

export const getWeekDaysAbbreviations = () => {
  return [
    i18next.t(WEEKDAY__MONDAY_ABBREVIATION),
    i18next.t(WEEKDAY__TUESDAY_ABBREVIATION),
    i18next.t(WEEKDAY__WEDNESDAY_ABBREVIATION),
    i18next.t(WEEKDAY__THURSDAY_ABBREVIATION),
    i18next.t(WEEKDAY__FRIDAY_ABBREVIATION),
    i18next.t(WEEKDAY__SATURDAY_ABBREVIATION),
    i18next.t(WEEKDAY__SUNDAY_ABBREVIATION),
  ];
};

export const treatAsUTC = (date: Date) => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

export function getUpDropdownItemsFromEnum(enumObject: object): UPDropdownItem[] {
  return (Object.keys(enumObject) as Array<keyof typeof enumObject>).map(key => ({
    value: enumObject[key],
    label: enumObject[key],
  }));
}

export function getUpDropdownItemsWithIdAsValueFromEnum(enumObject: object): UPDropdownItem[] {
  return (Object.keys(enumObject) as Array<keyof typeof enumObject>).map(key => ({
    value: (Object.keys(enumObject).indexOf(key) + 1).toString(),
    label: enumObject[key],
  }));
}

export function getModesOfTransportWithTranslation(): UPDropdownItem[] {
  return (Object.keys(ModeOfTransport) as Array<keyof typeof ModeOfTransport>).map(key => ({
    value: (Object.keys(ModeOfTransport).indexOf(key) + 1).toString(),
    label: useModesOfTransportTranslation(Object.keys(ModeOfTransport).indexOf(key) + 1),
  }));
}

export function getReasonForEmploymentWithTranslation(): UPDropdownItem[] {
  return (Object.keys(ReasonForEmployment) as Array<keyof typeof ReasonForEmployment>).map(key => ({
    value: (Object.keys(ReasonForEmployment).indexOf(key) + 1).toString(),
    label: useReasonForEmploymentTranslation(Object.keys(ReasonForEmployment).indexOf(key) + 1),
  }));
}

export function getFunctionTypesWithTranslation(): UPDropdownItem[] {
  return (Object.keys(FunctionType) as Array<keyof typeof FunctionType>).map(key => ({
    value: (Object.keys(FunctionType).indexOf(key) + 1).toString(),
    label: useFunctionTypesTranslation(Object.keys(FunctionType).indexOf(key) + 1),
  }));
}

export function getUserRolesWithTranslation(): UPDropdownItem[] {
  return (Object.keys(Role) as Array<keyof typeof Role>).map(key => ({
    value: (Object.keys(Role).indexOf(key) + 1).toString(),
    label: useRolesTranslations(Object.keys(Role).indexOf(key) + 1),
  }));
}

export function getPaymentMethodsWithTranslation(): UPDropdownItem[] {
  return (Object.keys(PaymentMethod) as Array<keyof typeof PaymentMethod>).map(key => ({
    value: (Object.keys(PaymentMethod).indexOf(key) + 1).toString(),
    label: usePaymentMethodsTranslation(Object.keys(PaymentMethod).indexOf(key) + 1),
  }));
}

export function getReasonForNoShowWithTranslation(): UPDropdownItem[] {
  return (Object.keys(ReasonForNoShow) as Array<keyof typeof ReasonForNoShow>).map(key => ({
    value: (Object.keys(ReasonForNoShow).indexOf(key) + 1).toString(),
    label: useReasonForNoShowTranslation(Object.keys(ReasonForNoShow).indexOf(key) + 1),
  }));
}

export function allowOnlyPositiveNumber(value: string) {
  return value.replace(/\D/g, '');
}

export function allowOnlyFixedRange(value: string) {
  if (/^\d$/.test(value) || /^[1-9]\d$/.test(value)) {
    return value;
  }
  return '';
}

export function getInitials(firstName: string, lastName: string) {
  return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
}

export function getFormattedCompanyAddress(address: Address): string {
  if (address) {
    let formattedAddress = '';
    if (address.streetName) {
      formattedAddress = address.streetName;
    }
    if (address.streetNumber) {
      formattedAddress += `, ${address.streetNumber}`;
    }
    if (address.city) {
      formattedAddress += `, ${address.city}`;
    }
    if (address.postalCode) {
      formattedAddress += `, ${address.postalCode}`;
    }

    return formattedAddress;
  }
  return '';
}

export const validatePassword = (password: string): boolean => {
  // Check if the password meets the minimum length requirement
  if (password.length < 8 || password.length > 100) {
    return false;
  }

  // Check if the password contains a combination of letters, numbers, and symbols
  if (!/[a-zA-Z]/.test(password) || !/\d/.test(password) || !/\W/.test(password)) {
    return false;
  }

  return true;
};

export const getFirstDayOfWeek = (w: number, year: number, datePickedForYear?: Date) => {
  const currentDate = new Date(Date.now());
  let y = datePickedForYear?.getFullYear() ?? currentDate.getFullYear();

  if (year) {
    y = year;
  }

  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
};

export const addOrSubtractDays = (days: number, date: Date) => {
  const newDate = new Date(date);

  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const generateCurrentWeekDays = (currentWeek: number, year: number, datePickedForYear?: Date) => {
  const firstDayOfWeek = getFirstDayOfWeek(currentWeek, year, datePickedForYear);
  const week: { date: Date; checked: boolean }[] = [
    {
      date: firstDayOfWeek,
      checked: false,
    },
  ];
  let counter = 1;
  while (counter <= 6) {
    week.push({
      date: addOrSubtractDays(counter, firstDayOfWeek),
      checked: false,
    });
    ++counter;
  }
  return week;
};

export const getNewFiltersFromPayload = (
  payload: { filterType: CompanyAvailabilityFilterType | ContractsFilterType; value: string | number }[],
) => {
  return payload.reduce((acc, filter) => {
    return {
      ...acc,
      [filter.filterType]: filter.value,
    };
  }, {});
};
