import { Button, Card, Typography } from '@mui/material';
import { Box, display, lighten, styled } from '@mui/system';
import UPButton from 'components/button';

export const GridColumnStyled = styled(Box)(({ theme }) => ({
  minWidth: '210px',
  width: '33%',
  overflow: 'auto',
}));

export const SideGridColumnStyled = styled(Box)(() => ({
  width: '100%',
  overflow: 'auto',
}));

export const GradientColumnStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  position: 'absolute',
  zIndex: '1',
  cursor: 'pointer',
  [theme.breakpoints.down(600)]: {
    width: '50%',
  },
}));

export const RightGradientColumnStyled = styled(GradientColumnStyled)(() => ({
  backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1))',
}));

export const LeftGradientColumnStyled = styled(GradientColumnStyled)(() => ({
  backgroundImage: 'linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1))',
  position: 'absolute',
  top: '0',
  right: '0',
}));

export const CardStyled = styled(Card)(() => ({
  width: '210px',
  minHeight: '107px',
  backgroundColor: '#FAFAFA',
  border: '2px solid white',
  boxShadow: 'unset',
  borderRadius: '8px',
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',

  '&:hover': {
    backgroundColor: '#F5F6F6',
  },
}));

export const CardForBlogsStyled = styled(Card)(({ theme }) => ({
  width: '100%',
  minHeight: '107px',
  backgroundColor: '#ehite',
  boxShadow: 'unset',
  borderRadius: '8px',
  padding: '15px',
  display: 'flex',
  marginBottom: '10px',
  flexDirection: 'column',
  height: '96px',

  '&:hover': {
    backgroundColor: '#F5F6F6',
  },

  [theme.breakpoints.down(1000)]: {
    height: 'unset',
    maxHeight: '343px',
  },
}));

export const DashboardContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  margin: '0',
  [theme.breakpoints.down(1000)]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const ContractsContainer = styled(Box)(({ theme }) => ({
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  marginRight: '10px',
  height: 'fit-content',
  [theme.breakpoints.down(1000)]: {
    marginRight: '0px',
    marginBottom: '10px',
    width: '90%',
  },
  [theme.breakpoints.down(420)]: {
    marginRight: '0px',
    marginBottom: '10px',
    width: '100%',
  },
}));

export const ContractsOverview = styled(Box)({
  height: '350px',
});

export const ContractsListParent = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  [theme.breakpoints.down(1000)]: {
    minHeight: '30px',
  },
}));

export const DashboardRightSide = styled(Box)(({ theme }) => ({
  width: '76%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down(1000)]: {
    width: '92%',
  },
}));

export const ShortcutsContainer = styled(Box)(({ theme }) => ({
  height: '20%',
  [theme.breakpoints.down(1000)]: {
    height: 'unset',
    marginBottom: '10px',
  },
}));

export const ShortcutsListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down(1000)]: {
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
}));

export const OldEmployeeContainer = styled(Box)(({ theme }) => ({
  height: '20%',
  [theme.breakpoints.down(1000)]: {
    height: 'unset',
    marginBottom: '10px',
  },
}));

export const EmployeeContainer = styled(Box)(({ theme }) => ({
  marginLeft: '5px',
  borderRadius: '10px',
  width: '50%',
  [theme.breakpoints.down(1000)]: {
    height: 'unset',
    marginBottom: '10px',
    width: '100%',
    marginLeft: '0px',
  },
}));

export const CostEstimationContainer = styled(Box)(({ theme }) => ({
  marginLeft: '5px',
  borderRadius: '10px',
  width: '50%',
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
}));

export const BlogContainer = styled(Box)(({ theme }) => ({
  marginLeft: '5px',
  borderRadius: '10px',
  width: '50%',
  [theme.breakpoints.down(1000)]: {
    height: 'unset',
    marginBottom: '10px',
    width: '100%',
    marginLeft: '0px',
    marginTop: '55px',
  },
  [theme.breakpoints.down(670)]: {
    marginTop: '45px',
  },
}));

export const DashboardTilesContainer = styled(Box)(({ theme }) => ({
  height: '50%',
  width: '100%',
  minHeight: '320px',
  display: 'flex',
  [theme.breakpoints.down(1000)]: {
    flexDirection: 'column',
  },
}));

export const DashboardEmployeeStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  color: '#303C42',
  fontSize: '14px',
  [theme.breakpoints.down(1000)]: {
    flexDirection: 'column',
  },
}));

export const DashboardTileStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  fontSize: '14px',
  backgroundColor: lighten(theme.palette.secondary.light, 0.8),
  borderRadius: '10px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
  flexDirection: 'column',
  [theme.breakpoints.down(1000)]: {
    minHeight: '300px',
    width: '100%',
  },
}));

export const DashboardTileTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: '100%',
  height: '15%',
  padding: '0px 10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 'bold',
}));

export const DashboardEmployeeNumbers = styled(Box)(() => ({
  height: '40%',
  display: 'flex',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
  paddingBottom: '2px',
  justifyContent: 'space-evenly',
}));

export const DashboardEmployeeActions = styled(Box)(() => ({
  height: '45%',
  display: 'flex',
  padding: '10px',
}));

export const StyledCostTooltip = styled(Box)(() => ({
  width: '250px',
  height: '250px',
  backgroundColor: 'white',
  borderRadius: '5px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
  zIndex: '2000',
}));

export const BigCostTooltipContainer = styled(Box)(() => ({
  height: '25%',
  display: 'flex',
  justifyContent: 'space-between',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
  alignItems: 'flex-end',
  padding: '10px 40px',
}));

export const SmallCostTooltipContainer = styled(Box)(() => ({
  height: '15%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 40px',
}));

export const InvoicesButtonContainer = styled(Box)(() => ({
  height: '25%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
}));

export const ViewInvoicesButton = styled(UPButton)(() => ({ height: '75%' }));

export const DashboardEmployeeActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  padding: '10px 30px 10px 15px',
  width: '55%',
  [theme.breakpoints.down(1300)]: {
    width: '100%',
  },
  [theme.breakpoints.down(1000)]: {
    width: '100%',
    padding: '10px',
  },
}));

export const DashboardEmployeeAvatarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '45%',
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
}));

export const EmployeeNumberContainerStyled = styled(Box)(() => ({
  padding: '10px',
  width: '33%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
}));

export const AddEmployeeButton = styled(UPButton);

export const GoToEmployeesButtonStyled = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  [theme.breakpoints.down(690)]: {
    fontSize: '12px',
  },
}));

export const InvitedEmployeesNumberStyled = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '40px',
  fontWeight: 'bold',
}));

export const ReadyEmployeesNumberStyled = styled(Box)(({ theme }) => ({
  color: lighten(theme.palette.primary.main, 0.6),
  fontSize: '40px',
  fontWeight: 'bold',
}));

export const DeployedEmployeesNumberStyled = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '40px',
  fontWeight: 'bold',
}));

export const InvitedTitle = styled(Typography)(({ theme }) => ({
  height: '20%',
  fontSize: '0.85rem',
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
  [theme.breakpoints.down(845)]: {
    fontSize: '0.75rem',
  },
}));

export const ReadyTitle = styled(Typography)(({ theme }) => ({
  height: '20%',
  fontSize: '0.85rem',
  color: lighten(theme.palette.primary.main, 0.6),
  fontWeight: 'bold',
  [theme.breakpoints.down(845)]: {
    fontSize: '0.75rem',
  },
}));

export const DeployedTitle = styled(Typography)(({ theme }) => ({
  height: '20%',
  fontSize: '0.85rem',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  [theme.breakpoints.down(845)]: {
    fontSize: '0.75rem',
  },
}));

export const EmployeeRightSideContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#71B790',
  borderRadius: '0px 10px 10px 0px',
  display: 'flex',
  padding: '10px 10px 10px 20px',
  justifyContent: 'space-between',
  width: '50%',
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
}));

export const EmployeeDownSideContainer = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down(1000)]: {
    backgroundColor: '#71B790',
    borderRadius: '0px 0px 10px 10px',
    width: '100%',
    display: 'flex',
    padding: '10px 10px 10px 20px',
    justifyContent: 'space-evenly',
    height: '100px',
    alignItems: 'center',
  },
}));

export const EmployeeLeftSideContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#DBEDE3',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px 0px 0px 10px',
  justifyContent: 'space-between',
  padding: '10px',
  color: '#008C15',
  width: '50%',
  [theme.breakpoints.down(1000)]: {
    borderRadius: '10px 10px 0px 0px',
    width: '100%',
  },
}));

export const BlogsList = styled(Box)(({ theme }) => ({
  height: '100%',
  marginTop: '10px',
  [theme.breakpoints.down(1000)]: {
    height: 'unset',
    marginTop: '10px',
    minHeight: '210px',
  },
}));

export const BlogsContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  fontSize: '14px',
  overflow: 'auto',
  [theme.breakpoints.down(1000)]: {
    padding: '0px',
  },
}));

export const ContractsGraphSection = styled(Box)(() => ({
  height: '100%',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: '#FFF',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
}));

export const GraphTitle = styled(Box)(({ theme }) => ({
  height: '20%',
  display: 'flex',
  borderRadius: '5px 5px 0px 0px',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 15px',
  color: '#fff',
  backgroundColor: lighten(theme.palette.primary.main, 0.4),
}));

export const GraphDisplayContainer = styled(Box)(() => ({
  height: '50%',
}));

export const GraphButtonContainer = styled(Box)(() => ({
  height: '30%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
}));

export const DashboardContractsTileStyled = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  color: '#303C42',
  padding: '10px',
  fontSize: '16px',
  backgroundColor: '#FFFFFF',
}));

export const DashboardContractsDateContainer = styled(Box)(() => ({
  display: 'flex',
  padding: '10px',
  justifyContent: 'space-between',
}));

export const DashboardContractsDayOfWeek = styled(Box)(() => ({
  paddingRight: '6px',
  fontWeight: 'bold',
}));

export const DashboardLocationDropdownContainer = styled(Box)(() => ({
  width: '50%',
  margin: '0px auto',
}));

export const DashboardContractCardsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-evenly',
  padding: '10px',
  overflow: 'hidden',
});

export const NumberOfItems = styled(Box)(() => ({
  width: '32px',
  height: '20px',
  background: '#71B790',
  borderRadius: '16px',
  color: 'white',
  textAlign: 'center',
  fontSize: '12px',
  lineHeight: '20px',
  marginTop: '1px',
  marginLeft: '5px',
}));

export const ContractsSideLeftColumnStyled = styled(Box)(({ theme }) => ({
  minWidth: '210px',
  position: 'relative',
  [theme.breakpoints.down(600)]: {
    display: 'none',
  },
}));

export const ContractsSideRightColumnStyled = styled(Box)(({ theme }) => ({
  minWidth: '210px',
  position: 'relative',
  [theme.breakpoints.down(600)]: {
    display: 'none',
  },
}));

export const DashboardErrorLogsTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  display: 'flex',
  alignItems: 'end',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  marginRight: '20px',
  marginBottom: '10px',
}));

export const ErrorLogsHeader = styled(Box)(() => ({
  display: 'flex',
}));

export const ErrorDayContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '10px 0px',
  padding: '10px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
  borderRadius: '5px',
  backgroundColor: '#fff',
}));

export const ErrorDateStyled = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '15px',
  fontWeight: 'bold',
}));

export const ErrorListContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
}));

export const ErrorCardStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '10px 5px',
  padding: '5px 10px',
  backgroundColor: theme.palette.primary.light,
  borderRadius: '5px',
}));

export const ErrorCardTop = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.error.main,
}));

export const ErrorCardBottom = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ErrorCardMessage = styled(Typography)(({ theme }) => ({
  fontSize: '26px',
  color: theme.palette.error.main,
}));
