import { UPDropdownItem } from 'components/dropdown';
import { CompanyRole } from 'constants/CompanyRole';
import { ReportType } from 'constants/ReportType';
import { t } from 'i18next';
import { getISO8601Year } from 'modules/contracts/helpers';
import {
  EMPLOYEES__EXTRA,
  EMPLOYEES__FLEXI,
  EMPLOYEES__LABOURER,
  EMPLOYEES__SERVANT,
  EMPLOYEES__STUDENT,
  GENDER__MAN,
  GENDER__WOMAN,
  REPORTING__CLIENT_PERFORMANCE_REPORT,
  REPORTING__GENERAL_STATISTICS,
  REPORTING__NOSHOW_HOURS,
  REPORTING__PLANNED_HOURS,
  REPORTING__REQUIRED_ACTIONS,
  REPORTING__WEEKLY_CONTRACT_COUNT,
  REPORTING__WORKED_HOURS,
  GENERAL__JANUARY,
  GENERAL__DECEMBER,
  GENERAL__APRIL,
  GENERAL__AUGUST,
  GENERAL__FEBRUARY,
  GENERAL__JULY,
  GENERAL__JUNE,
  GENERAL__MARCH,
  GENERAL__MAY,
  GENERAL__NOVEMBER,
  GENERAL__OCTOBER,
  GENERAL__SEPTEMBER,
  REPORTING__STATISTICS_PER_COST_CENTER,
  REPORTING__FTE_CHECK,
} from 'translations/constants';
import IEmployeeWithWorkerclass from 'types/employee/IEmployeeWithWorkerclass';
import Lang from 'types/Lang';
import { RequiredActionsReportData, StatisticsPerCostCenterData, WeeklyContractReportData } from 'types/reporting';
import { ClientPerformanceData } from 'types/reporting/ClientPerformanceData';
import FTECheckData from 'types/reporting/FTECheckData';
import ReportingResult from 'types/reporting/ReportingResult';
import { getUpDropdownItemsWithIdAsValueFromEnum } from 'utils/helpers';

export const mapDataToWeeklyReportData = (data: ReportingResult): WeeklyContractReportData[] => {
  if (!data?.items) return [];
  return data.items.map((item: any) => {
    return {
      companyId: item.companyId,
      companyName: item.companyName,
      payrollOfficer: item.payrollOfficer,
      jointComission: item.paritaireComite,
      paritaireComiteId: item.paritaireComiteId,
      activeContractCount: item.activeContractCount,
      pastContractCount: item.pastContractCount,
      totalHours: item.totalHours,
      status: item.status,
    };
  });
};

export const mapDataToRequiredActionsReportData = (data: ReportingResult): RequiredActionsReportData[] => {
  if (!data?.items) return [];
  return data.items.map(item => {
    return {
      companyId: item.companyId,
      companyName: item.companyName,
      employeeName: item.employeeName,
      employeeId: item.employeeId,
    };
  });
};

export const mapDataToClientPerformanceReport = (data: ReportingResult): ClientPerformanceData[] => {
  if (!data?.items) return [];

  return data.items.map(item => {
    return {
      companyId: item.companyId,
      companyName: item.companyName,
      currentMonthHours: item.currentMonthHours,
      pastMonthHours: item.pastMonthHours,
      pastYearHours: item.pastYearHours,
    };
  });
};

export const mapDataToStatisticsPerCostCenterReport = (data: ReportingResult): StatisticsPerCostCenterData[] => {
  if (!data?.items) return [];

  return data.items.map((item): StatisticsPerCostCenterData => {
    return {
      costCenterId: item.costCenterId,
      costCenterName: item.costCenterName,
      contractHours: item.contractHours,
      performedHours: item.performedHours,
      noShowHours: item.noShowHours,
      totalContractedCost: item.totalContractedCost,
      totalPerformedCost: item.totalPerformedCost,
    };
  });
};

export const mapDataToFTECheckReport = (data: ReportingResult): FTECheckData[] => {
  if (!data?.items) return [];

  return data.items.map((item): FTECheckData => {
    return {
      costCenterId: item.costCenterId,
      costCenterName: item.costCenterName,
      employeeId: item.employeeId,
      employeeName: item.employeeName,
      performedHours: item.performedHours,
      noShowHours: item.noShowHours,
      fteCheck: item.fteCheck,
    };
  });
};

export const getEmployeesDropdownValues = (employees: IEmployeeWithWorkerclass[]): UPDropdownItem[] => {
  return employees.map(employee => ({
    label: `${employee.firstName} ${employee.lastName}`,
    value: employee.id.toString(),
  }));
};

export const getWorkerClassesDropdownValues = (): UPDropdownItem[] => [
  {
    label: t(EMPLOYEES__LABOURER),
    value: 'ARB',
  },
  {
    label: t(EMPLOYEES__SERVANT),
    value: 'BED',
  },
  {
    label: t(EMPLOYEES__STUDENT),
    value: 'STUD',
  },
  {
    label: t(EMPLOYEES__EXTRA),
    value: 'EXT',
  },
  {
    label: t(EMPLOYEES__FLEXI),
    value: 'FLEX',
  },
];

export const getReportTypesDropdownValues = (role: CompanyRole): UPDropdownItem[] => {
  const reports = [
    {
      label: t(REPORTING__WEEKLY_CONTRACT_COUNT),
      value: '1',
    },
    {
      label: t(REPORTING__GENERAL_STATISTICS),
      value: '2',
    },
    {
      label: t(REPORTING__REQUIRED_ACTIONS),
      value: '3',
    },
    {
      label: t(REPORTING__CLIENT_PERFORMANCE_REPORT),
      value: '4',
    },
    {
      label: t(REPORTING__STATISTICS_PER_COST_CENTER),
      value: '5',
    },
    {
      label: t(REPORTING__FTE_CHECK),
      value: '6',
    },
  ];

  if (role !== CompanyRole.ADMIN) {
    return reports.slice(-2);
  }

  return reports;
};

export const getLanguageDropdownValues = (): UPDropdownItem[] => {
  return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
};

export const getGenderDropdownValues = (): UPDropdownItem[] => [
  {
    label: t(GENDER__MAN),
    value: '1',
  },
  {
    label: t(GENDER__WOMAN),
    value: '2',
  },
];

export const getReportStatusDropdownValues = (): UPDropdownItem[] => [
  { label: 'Exported', value: 'Exported' },
  { label: 'Active', value: 'Active' },
  { label: 'Completed', value: 'Completed' },
];

export const getYearsDropdownValues = (): UPDropdownItem[] => {
  const years = [];
  for (let i = 2022; i <= getISO8601Year(new Date()); i++) {
    years.push({ label: i.toString(), value: i.toString() });
  }
  return years;
};

export const getMonthsDropdownValues = (): UPDropdownItem[] => {
  const months = [];
  months.push({ label: t(GENERAL__JANUARY), value: '1' });
  months.push({ label: t(GENERAL__FEBRUARY), value: '2' });
  months.push({ label: t(GENERAL__MARCH), value: '3' });
  months.push({ label: t(GENERAL__APRIL), value: '4' });
  months.push({ label: t(GENERAL__MAY), value: '5' });
  months.push({ label: t(GENERAL__JUNE), value: '6' });
  months.push({ label: t(GENERAL__JULY), value: '7' });
  months.push({ label: t(GENERAL__AUGUST), value: '8' });
  months.push({ label: t(GENERAL__SEPTEMBER), value: '9' });
  months.push({ label: t(GENERAL__OCTOBER), value: '10' });
  months.push({ label: t(GENERAL__NOVEMBER), value: '11' });
  months.push({ label: t(GENERAL__DECEMBER), value: '12' });

  return months;
};

export const getContractHoursTypeDropdownValues = (): UPDropdownItem[] => [
  { label: t(REPORTING__PLANNED_HOURS), value: '1' },
  { label: t(REPORTING__WORKED_HOURS), value: '2' },
  { label: t(REPORTING__NOSHOW_HOURS), value: '3' },
];

export const isReportWithInterval = (type: any) => {
  return type === ReportType.FTE_CHECK || type === ReportType.STATISTICS_PER_COST_CENTER;
};
