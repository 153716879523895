import { Box, Tooltip } from '@mui/material';
import UPCheckbox from 'components/app-container/checkbox-with-label';
import UPButtonGroup from 'components/button/UPGroupButton';
import { UPToggleButton } from 'components/button/styles';
import UPDatepicker from 'components/datepicker';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import UPMultiselectDropdown from 'components/app-container/multiselect-dropdown';
import UPInput from 'components/input';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { getBicAction, setBicAction } from 'store/actions/financial-actions';
import { onboardingSelector } from 'store/selectors/employee-selector';
import { bicRequiredErrorSelector, bicStateSelector } from 'store/selectors/financial-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';

import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { getLocationsDropdownItemsAction } from 'store/actions/company-locations-actions';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import {
  EMPLOYEES__EXTRA,
  EMPLOYEES__EXTRA_TOOLTIP,
  EMPLOYEES__FLEXI,
  EMPLOYEES__FLEXI_TOOLTIP,
  EMPLOYEES__LABOURER,
  EMPLOYEES__LABOURER_TOOLTIP,
  EMPLOYEES__MODE_OF_TRANSPORT,
  EMPLOYEES__PERMANENT,
  EMPLOYEES__REASON_FOR_EMPLOYMENT,
  EMPLOYEES__SERVANT,
  EMPLOYEES__SERVANT_TOOLTIP,
  EMPLOYEES__START_DATE,
  EMPLOYEES__STUDENT,
  EMPLOYEES__STUDENT_TOOLTIP,
  EMPLOYEES__VALIDATE_YEARS_OF_EXPERIENCE,
  EMPLOYEES__WORKING_INFO,
  EMPLOYEES__YEARS_OF_EXPERIENCE,
  EMPLOYEE__COMPANY_LOCATION,
  GENERAL__ARB,
  GENERAL__AUTOMATIC,
  GENERAL__BED,
  GENERAL__EXT,
  GENERAL__FLEX,
  GENERAL__SELECT,
  GENERAL__STUD,
  GENERAL__WORKERCLASS,
  VALIDATION__IS_REQUIRED,
} from 'translations/constants';
import {
  allowOnlyPositiveNumber,
  getInputErrorText,
  getModesOfTransportWithTranslation,
  getReasonForEmploymentWithTranslation,
} from 'utils/helpers';
import { UPDropdownHelperText } from 'components/dropdown/styles';
import { EmployeeInfoSmallBox, EmployeeModalInfoContainer, RowContainer, SectionTitle, WorkerclassBox } from './styles';
import { IContactInfoTab } from './types';

const WorkingInfoTab = (props: IContactInfoTab) => {
  const {
    control,
    errors,
    watch,
    companyId,
    employeeId,
    setValue,
    clearErrors,
    employee,
    setIsBranchDropdownOpen,
    onBoardingLocationIds,
  } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const employeeBic = useAppSelector(bicStateSelector);
  const bicRequiredError = useAppSelector(bicRequiredErrorSelector);
  const profile = useAppSelector(profileSelector);
  const isEmployee = profile?.role === CompanyRole.EMPLOYEE;

  const isManagerEdit =
    ['Company manager', 'Store/location manager', 'HR manager'].includes(profile?.role) && employeeId > 0;

  const employeeOnboarding = useAppSelector(onboardingSelector);
  const locationsDropdownItems = useAppSelector(locationsDropdownItemsSelector);

  const locations = useMemo(
    () => [{ value: '-1', label: 'All' }].concat(locationsDropdownItems),
    [locationsDropdownItems],
  );
  const [selectedCompanyLocations, setSelectedCompanyLocations] = useState<UPDropdownItem[]>([]);
  const [noChangeOnLocation, setNoChangeOnLocation] = useState<boolean>(false);
  const modeOfTransportOptions = getModesOfTransportWithTranslation();
  const reasonForEmploymentOptions = getReasonForEmploymentWithTranslation();

  const watchPermanent = watch('permanent');

  const onChangeBic = useCallback(
    event => {
      dispatch(setBicAction(event.target.value));
    },
    [dispatch],
  );

  useEffect(() => {
    if (toNumber(companyId) > 0) {
      dispatch(getLocationsDropdownItemsAction({ companyId: toNumber(companyId), includeAllForManager: true }));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    if (employeeId > 0) {
      const currentLocations = employee?.companyLocationIds;
      if (currentLocations?.length > 0 && !noChangeOnLocation) {
        const selectedLocations = locations.filter(i => currentLocations.includes(i.value.toString()));
        setSelectedCompanyLocations(selectedLocations);
      }
    } else if (employeeOnboarding) {
      const onBoardingLocations = locations.filter(i =>
        onBoardingLocationIds.map(x => x.toString()).includes(i.value.toString()),
      );

      setSelectedCompanyLocations(onBoardingLocations);
    }
  }, [employeeId, employeeOnboarding, employee, locations, onBoardingLocationIds, noChangeOnLocation]);

  useEffect(() => {
    const validLocations = locations?.filter(i => i.value !== '-1');
    if (validLocations.length === 1) {
      setValue('companyLocationIds', [validLocations[0].value]);
      clearErrors('companyLocationIds');
      setSelectedCompanyLocations([{ value: validLocations[0].value, label: validLocations[0].label }]);
    }
  }, [clearErrors, locations, setValue]);

  return (
    <Box>
      <RowContainer>
        <SectionTitle sx={{ display: 'flex', alignItems: 'center' }}>{t(EMPLOYEES__WORKING_INFO)}</SectionTitle>
        {!isEmployee && !employeeOnboarding && (
          <Box sx={{ marginLeft: '20px' }}>
            <Controller
              name="permanent"
              control={control}
              render={({ field }) => (
                <UPCheckbox label={t(EMPLOYEES__PERMANENT)} value={field.value} handleChange={field.onChange} />
              )}
            />
          </Box>
        )}
      </RowContainer>
      <EmployeeModalInfoContainer>
        {!watchPermanent && (
          <>
            <WorkerclassBox>
              <Controller
                name="workerclass"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPButtonGroup
                    label={t(GENERAL__WORKERCLASS)}
                    value={field.value}
                    onChange={field.onChange}
                    required
                    error={!!errors.workerclass}
                    helperText={getInputErrorText(errors, 'workerclass')}
                  >
                    <UPToggleButton value={1} disabled={isManagerEdit}>
                      <Tooltip
                        title={`${t(EMPLOYEES__STUDENT)}: ${t(EMPLOYEES__STUDENT_TOOLTIP)}`}
                        placement="top"
                        arrow
                      >
                        <span>{t(GENERAL__STUD)}</span>
                      </Tooltip>
                    </UPToggleButton>

                    <UPToggleButton value={2} disabled={isManagerEdit}>
                      <Tooltip
                        title={
                          <>
                            {`${t(EMPLOYEES__FLEXI)}: ${t(EMPLOYEES__FLEXI_TOOLTIP).substring(
                              0,
                              t(EMPLOYEES__FLEXI_TOOLTIP).lastIndexOf(' ') + 1,
                            )}`}
                            <a
                              href="https://www.update-pro.be/blog/artikel/infofiche-flexi-job/"
                              target="blank"
                              style={{
                                fontStyle: 'italic',
                                textDecoration: 'underline',
                                color: '#DBEDE3',
                              }}
                            >
                              {`${t(EMPLOYEES__FLEXI_TOOLTIP).substring(
                                t(EMPLOYEES__FLEXI_TOOLTIP).lastIndexOf(' ') + 1,
                              )}`}
                            </a>
                          </>
                        }
                        placement="top"
                        arrow
                      >
                        <span>{t(GENERAL__FLEX)}</span>
                      </Tooltip>
                    </UPToggleButton>
                    <UPToggleButton value={4} disabled={isManagerEdit}>
                      <Tooltip
                        title={`${t(EMPLOYEES__LABOURER)}: ${t(EMPLOYEES__LABOURER_TOOLTIP)}`}
                        placement="top"
                        arrow
                      >
                        <span>{t(GENERAL__ARB)}</span>
                      </Tooltip>
                    </UPToggleButton>
                    <UPToggleButton value={5} disabled={isManagerEdit}>
                      <Tooltip
                        title={`${t(EMPLOYEES__SERVANT)}: ${t(EMPLOYEES__SERVANT_TOOLTIP)}`}
                        placement="top"
                        arrow
                      >
                        <span>{t(GENERAL__BED)}</span>
                      </Tooltip>
                    </UPToggleButton>
                    <UPToggleButton value={3} disabled={isManagerEdit}>
                      <Tooltip title={`${t(EMPLOYEES__EXTRA)}: ${t(EMPLOYEES__EXTRA_TOOLTIP)}`} placement="top" arrow>
                        <span>{t(GENERAL__EXT)}</span>
                      </Tooltip>
                    </UPToggleButton>
                  </UPButtonGroup>
                )}
              />
            </WorkerclassBox>
            <EmployeeInfoSmallBox>
              <Controller
                name="reasonForEmployment"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPDropdown
                    value={field.value}
                    onChange={field.onChange}
                    label={t(EMPLOYEES__REASON_FOR_EMPLOYMENT)}
                    placeholder={t(GENERAL__SELECT)}
                    required
                    error={!!errors.reasonForEmployment}
                    helperText={getInputErrorText(errors, 'reasonForEmployment')}
                    className="dropdown--white-background"
                    items={reasonForEmploymentOptions}
                    disabled={isManagerEdit}
                  />
                )}
              />
            </EmployeeInfoSmallBox>
          </>
        )}
        <EmployeeInfoSmallBox>
          <Controller
            name="modeOfTransport"
            control={control}
            rules={{ required: !watchPermanent }}
            render={({ field }) => (
              <UPDropdown
                value={field.value}
                onChange={field.onChange}
                label={t(EMPLOYEES__MODE_OF_TRANSPORT)}
                placeholder={t(GENERAL__SELECT)}
                required={!watchPermanent}
                error={!!errors.modeOfTransport}
                helperText={getInputErrorText(errors, 'modeOfTransport')}
                className="dropdown--white-background"
                items={modeOfTransportOptions}
                disabled={isManagerEdit}
              />
            )}
          />
        </EmployeeInfoSmallBox>
        <Tooltip title={employeeOnboarding || isEmployee ? t(EMPLOYEES__VALIDATE_YEARS_OF_EXPERIENCE) : ''}>
          <EmployeeInfoSmallBox sx={{ width: '25%' }}>
            <Controller
              name="yearsOfExperience"
              control={control}
              rules={{ min: 0, max: 99, required: !employeeOnboarding && !isEmployee }}
              render={({ field: { value, onChange } }) => (
                <UPInput
                  value={employeeOnboarding ? 0 : value}
                  onChange={ev => onChange(allowOnlyPositiveNumber(ev.target.value))}
                  label={t(EMPLOYEES__YEARS_OF_EXPERIENCE)}
                  placeholder={t(EMPLOYEES__YEARS_OF_EXPERIENCE)}
                  disabled={isManagerEdit || isEmployee}
                  error={!!errors.yearsOfExperience}
                  helperText={getInputErrorText(errors, 'yearsOfExperience', undefined)}
                  inputClassName="white-background"
                  required={!employeeOnboarding && !isEmployee}
                />
              )}
            />
          </EmployeeInfoSmallBox>
        </Tooltip>
        {watchPermanent && !isManagerEdit && (
          <EmployeeInfoSmallBox>
            <Controller
              name="startDate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDatepicker
                  value={field.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__START_DATE)}
                  required
                  error={!!errors.startDate}
                  helperText={getInputErrorText(errors, 'startDate')}
                  className="datepicker--white-background"
                />
              )}
            />
          </EmployeeInfoSmallBox>
        )}
      </EmployeeModalInfoContainer>
      <EmployeeModalInfoContainer>
        {!isManagerEdit && (
          <>
            <EmployeeInfoSmallBox sx={{ width: '34%' }}>
              <Controller
                name="iban"
                control={control}
                rules={{ required: !watchPermanent, maxLength: 50 }}
                render={({ field }) => (
                  <UPInput
                    value={field.value}
                    onChange={event => {
                      if (event.target.value !== '' && !event.target.value.match(/^[a-zA-Z0-9]+$/)) return;
                      field.onChange(event);
                    }}
                    onBlur={() => {
                      if (field.value) {
                        dispatch(getBicAction({ id: '0', iban: field.value }));
                      }
                    }}
                    label="IBAN"
                    placeholder="IBAN"
                    required={!watchPermanent}
                    error={!!errors.iban}
                    helperText={getInputErrorText(errors, 'iban', undefined, 50)}
                    inputClassName="white-background"
                  />
                )}
              />
            </EmployeeInfoSmallBox>
            {employeeBic !== '' && (
              <EmployeeInfoSmallBox>
                <UPInput
                  value={employeeBic}
                  onChange={onChangeBic}
                  label={`BIC (${t(GENERAL__AUTOMATIC)})`}
                  placeholder={`BIC (${t(GENERAL__AUTOMATIC)})`}
                  required={!watchPermanent}
                  error={bicRequiredError}
                  helperText={bicRequiredError ? t(VALIDATION__IS_REQUIRED) : ''}
                  inputClassName="white-background"
                />
              </EmployeeInfoSmallBox>
            )}

            {employeeBic === '' && (
              <EmployeeInfoSmallBox>
                <Controller
                  name="bic"
                  control={control}
                  rules={{ required: !watchPermanent }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      label={`BIC (${t(GENERAL__AUTOMATIC)})`}
                      placeholder={`BIC (${t(GENERAL__AUTOMATIC)})`}
                      required={!watchPermanent}
                      error={!!errors.bic}
                      helperText={getInputErrorText(errors, 'bic')}
                      inputClassName="white-background"
                    />
                  )}
                />
              </EmployeeInfoSmallBox>
            )}
          </>
        )}
      </EmployeeModalInfoContainer>
      <EmployeeModalInfoContainer>
        <EmployeeInfoSmallBox sx={{ width: '100%' }}>
          <Controller
            name="companyLocationIds"
            control={control}
            rules={{ required: !employeeOnboarding && profile?.role !== CompanyRole.EMPLOYEE }}
            render={({ field }) => (
              <UPMultiselectDropdown
                items={locations}
                handleChange={value => {
                  setNoChangeOnLocation(true);
                  if (value && value.some(i => i.value === '-1')) {
                    // eslint-disable-next-line no-param-reassign
                    value = [{ value: '-1', label: 'All' }];
                  }

                  if (!value) {
                    // eslint-disable-next-line no-param-reassign
                    value = [];
                  }
                  field.onChange(value);
                  setSelectedCompanyLocations(value);
                }}
                onOpen={() => setIsBranchDropdownOpen(true)}
                onClose={() => setIsBranchDropdownOpen(false)}
                value={selectedCompanyLocations}
                label={t(EMPLOYEE__COMPANY_LOCATION)}
                required={!employeeOnboarding && profile?.role !== CompanyRole.EMPLOYEE}
                placeholder={locations.length === 0 ? 'No locations available' : t(EMPLOYEE__COMPANY_LOCATION)}
                disabled={locations.length <= 1 || employeeOnboarding}
                error={!!errors.companyLocationIds}
                helperText={getInputErrorText(errors, 'companyLocationIds')}
              />
            )}
          />
        </EmployeeInfoSmallBox>
      </EmployeeModalInfoContainer>
    </Box>
  );
};

export default WorkingInfoTab;
