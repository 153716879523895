import { Box, InputLabel, MenuItem, Pagination, Select } from '@mui/material';
import { CompanyAvailabilityFilterType } from 'constants/CompanyAvailabilityFilterType';
import { generateNumberOfPages } from 'modules/employees/helpers';
import { getISO8601Year } from 'modules/contracts/helpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { setCompanyAvailabilityFiltersAction } from 'store/actions/company-availability-filters-actions';
import { availabilityForWeekSelector } from 'store/selectors/availabilities-selectors';
import { AVAILABILITIES__NO_EMPLOYEES_FOUND, GENERAL__ITEMS_PER_PAGE } from 'translations/constants';
import { IEmployeeAvailabilityWeek } from 'types/availabilities';
import { generateCurrentWeekDays } from 'utils/helpers';
import { getAvailabilityCountForWeek } from '../helpers';
import { CompanyAvailabilityTableProps } from '../types';
import CompanyAvailabilityTableHeader from './header';
import CompanyAvailabilityTableRow from './row';

const CompanyAvailabilityTable = (props: CompanyAvailabilityTableProps): JSX.Element => {
  const {
    week,
    setWeek,
    setYear,
    currentWeek,
    companyId,
    itemsPerPage,
    setItemsPerPage,
    numberOfPages,
    setNumberOfPages,
    currentPage,
    days,
    setDays,
    setCurrentPage,
    selectedEmployee,
    setSelectedEmployee,
    setAddContractModalOpen,
    setContractSaved,
    createdContractsArray,
    areAllCardsUnhovered,
  } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const availabilityForWeek = useAppSelector(availabilityForWeekSelector);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [displayedAvailabilities, setDisplayedAvailabilities] = useState<IEmployeeAvailabilityWeek[]>([]);
  const [availableEmployeesCount, setAvailableEmployeesCount] = useState<number[]>([]);

  useEffect(() => {
    const weekDays = generateCurrentWeekDays(currentWeek, getISO8601Year(week), week);
    setDays(weekDays);
    setSelectedEmployee(null);
  }, [currentWeek, week, setDays, setSelectedEmployee]);

  useEffect(() => {
    if (availabilityForWeek?.employeeAvailabilities?.items) {
      const availabilityCountByDay = getAvailabilityCountForWeek(
        availabilityForWeek?.employeeAvailabilities.items,
        days,
      );
      setAvailableEmployeesCount(availabilityCountByDay);
    }
  }, [availabilityForWeek?.employeeAvailabilities, days, currentWeek, week]);

  useEffect(() => {
    if (availabilityForWeek) {
      setDisplayedAvailabilities(availabilityForWeek.employeeAvailabilities.items);
      const totalPages = generateNumberOfPages(
        itemsPerPage,
        availabilityForWeek.employeeAvailabilities.numberOfResults,
      );
      setNumberOfPages(totalPages);
    }
  }, [availabilityForWeek, itemsPerPage, setNumberOfPages]);

  const onSearch = () => {
    dispatch(
      setCompanyAvailabilityFiltersAction([{ filterType: CompanyAvailabilityFilterType.NAME, value: searchQuery }]),
    );
  };

  const onSelectDay = (employeeId: number, day: number) => {
    setContractSaved(false);
    const selectedDays = days.filter(d => d.checked);
    if (selectedDays.length === 1 && selectedDays[0].date.getDate() === day && selectedEmployee === employeeId) {
      setSelectedEmployee(null);
      const newDays = days.map(d => ({ ...d, checked: false }));
      setDays(newDays);
      return;
    }
    setSelectedEmployee(employeeId);
    const dayIndex = days.findIndex(d => d.date.getDate() === day);
    const newDays = days.map((d, index) => {
      if (index === dayIndex) {
        return { ...d, checked: !d.checked };
      }
      return d;
    });
    setDays(newDays);
  };

  return (
    <Box>
      <CompanyAvailabilityTableHeader
        week={week}
        setWeek={setWeek}
        setYear={setYear}
        days={days}
        searchQuery={searchQuery}
        onChangeSearchQuery={setSearchQuery}
        onSearch={onSearch}
        availabilityCountByDay={availableEmployeesCount}
      />
      {displayedAvailabilities.map(employeeAvailability => (
        <CompanyAvailabilityTableRow
          key={employeeAvailability?.employeeId}
          employeeAvailability={employeeAvailability}
          companyAvailabilityIntervals={availabilityForWeek?.companyAvailabilityIntervals}
          month={days[6].date.getMonth() + 1}
          companyId={companyId}
          selectedEmployee={selectedEmployee}
          onSelectDay={onSelectDay}
          days={days}
          openModal={() => setAddContractModalOpen(true)}
          createdContractsArray={createdContractsArray}
          areAllCardsUnhovered={areAllCardsUnhovered}
        />
      ))}
      {displayedAvailabilities.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          {t(AVAILABILITIES__NO_EMPLOYEES_FOUND)}
        </Box>
      )}
      {companyId && displayedAvailabilities.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InputLabel id="paginationLabel" sx={{ fontSize: '13px', paddingRight: '10px' }}>
              {t(GENERAL__ITEMS_PER_PAGE)}
            </InputLabel>
            <Select
              sx={{ fontSize: '13px', height: '33px' }}
              labelId="paginationLabel"
              id="select"
              value={`${itemsPerPage}`}
              onChange={e => {
                setItemsPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
            >
              <MenuItem value="20">20</MenuItem>
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </Select>
          </Box>
          <Pagination
            count={numberOfPages}
            page={currentPage}
            onChange={(_, page) => setCurrentPage(page)}
            sx={{ paddingLeft: '10px' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CompanyAvailabilityTable;
