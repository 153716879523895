import { TableCell, styled } from '@mui/material';

export const CalendarCellStyled = styled(TableCell)<{ selected?: boolean; isDay?: boolean }>(
  ({ theme, selected, isDay }) => ({
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderBottom: 'none',
    borderRadius: theme.spacing(1 / 4),
    cursor: isDay ? 'pointer' : 'default',
    backgroundColor: (() => {
      if (isDay) {
        return selected ? theme.palette.primary.main : theme.palette.primary.light;
      }
      return 'transparent';
    })(),
    color: selected ? 'white' : theme.palette.secondary.dark,
    fontWeight: 'bold',
  }),
);

export const EmptyCalendarCellStyled = styled(TableCell)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderBottom: 'none',
}));
