/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, InputLabel, TextField } from '@mui/material';
import { UPMultiselectDropdownProps } from './types';
import { UpMultiSelectInput } from './styles';

const UPMultiselectDropdown = (props: UPMultiselectDropdownProps): JSX.Element => {
  const {
    items,
    label,
    placeholder,
    handleChange,
    value,
    defaultValue,
    disabled,
    isOptionEqualToValue,
    required,
    error,
    helperText,
    onOpen,
    onClose,
  } = props;
  return (
    <>
      <InputLabel sx={{ color: ' #9EA2A2', fontSize: '12px', fontFamily: 'Montserrat,sans-serif' }}>
        {label} {required && '*'}
      </InputLabel>
      <Autocomplete
        multiple
        limitTags={3}
        id="multiple-limit-tags"
        options={items}
        filterSelectedOptions
        onChange={(event, newValue) => {
          if (newValue.length === 0) handleChange(null);
          else handleChange(newValue);
        }}
        value={value}
        isOptionEqualToValue={isOptionEqualToValue}
        disabled={disabled}
        getOptionLabel={option => option.label}
        defaultValue={defaultValue}
        renderInput={params => (
          <UpMultiSelectInput {...params} placeholder={placeholder} error={error} helperText={helperText} />
        )}
        onOpen={onOpen}
        onClose={onClose}
        sx={{
          '& .MuiInputBase-root': {
            height: 'unset',
          },
          '& .MuiOutlinedInput-root': {
            height: 'unset',
          },
        }}
      />
    </>
  );
};

UPMultiselectDropdown.defaultProps = {
  defaultValue: [],
  disabled: false,
  placeholder: '',
};

export default UPMultiselectDropdown;
