import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/system';
import { Header } from '../styles';
import { GridHeaderCell } from '..';
import { IDayProps } from '../types';
import { getISO8601Year } from '../helpers';

const GridHeader = (props: {
  onCheckDay: (date: Date, isDayChecked: boolean) => void;
  days: IDayProps[];
  onChangeWeek: (timePeriod: string, date: Date) => void;
}): JSX.Element => {
  const { onCheckDay, days, onChangeWeek } = props;

  const formatDate = (value: Date) => {
    const day = value.getDate();
    const m = value.toLocaleString('default', { month: 'short' });
    return `${day} ${m}`;
  };

  return (
    <Header>
      <Box>
        <KeyboardArrowLeftIcon onClick={() => onChangeWeek('past', days[0].date)} sx={{ cursor: 'pointer' }} />
      </Box>
      {days.map((d: IDayProps) => (
        <GridHeaderCell
          dayOfWeek={d.dayOfWeek}
          date={formatDate(d.date)}
          numberOfItems={d.numberOfItems}
          onCheckDay={onCheckDay}
          checked={d.checked}
          key={`${d.date}`}
          year={getISO8601Year(d.date)}
        />
      ))}
      <Box>
        <ChevronRightIcon
          onClick={() => onChangeWeek('future', days[days.length - 1].date)}
          sx={{ cursor: 'pointer' }}
        />
      </Box>
    </Header>
  );
};

export default GridHeader;
