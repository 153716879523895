import { Box } from '@mui/material';
import { UPDropdownHelperText } from 'components/dropdown/styles';
import { UPInputLabel } from 'components/input/styles';
import { ReactComponent as AttachmentIcon } from '../../assets/icons/Attachment.svg';
import { UPFileInputStyled, UIFileInputAttachmentIconContainer, UPFileInputBox, UIFileInputFileName } from './styles';
import { UPFileInputProps } from './types';

const UPFileInput = (props: UPFileInputProps): JSX.Element => {
  const { value, onChange, label, inputName, placeholder, className, error, disabled, required, helperText } = props;

  return (
    <Box className={className}>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>
      <UPFileInputBox error={error}>
        <UPFileInputStyled
          onChange={onChange}
          type="file"
          accept="image/*"
          name={inputName}
          disabled={disabled}
          required={required}
        />
        {value ? <UIFileInputFileName>{value}</UIFileInputFileName> : placeholder}
        <UIFileInputAttachmentIconContainer>
          <AttachmentIcon />
        </UIFileInputAttachmentIconContainer>
      </UPFileInputBox>
      {error && <UPDropdownHelperText>{helperText}</UPDropdownHelperText>}
    </Box>
  );
};

UPFileInput.defaultProps = {
  className: '',
  error: false,
  required: false,
  helperText: '',
};

export default UPFileInput;
