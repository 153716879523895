import { Backdrop, Box } from '@mui/material';
import { inviteEmployeeRequest } from 'api/requests/employee-requests';
import UPButton from 'components/button';
import { UPDropdown, UPDropdownItem, UPMultipleSelectionDropdown } from 'components/dropdown';
import UPInput from 'components/input';
import { UPLinkStyled } from 'components/link/styles';
import InviteEmployeeTab from 'components/tab/InviteEmployeeTab';
import { ContractTypeOptions } from 'constants/ContractTypes';
import { toNumber } from 'lodash';
import { SectionContainer } from 'modules/companies/styles';
import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { getLocationsDropdownItemsAction } from 'store/actions/company-locations-actions';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import {
  EMPLOYEES__ADD_GENERAL_INVITATION,
  EMPLOYEES__GENERAL_INVITATION_LIST,
  EMPLOYEES__INDIVIDUAL_INVITATION,
  EMPLOYEES__INVITE_EMPLOYEE_COPY_INVITATION_LINK,
  EMPLOYEES__INVITE_EMPLOYEE_SENT,
  EMPLOYEE__COMPANY_LOCATION,
  GENERAL__CANCEL_BUTTON,
  GENERAL__CLOSE,
  GENERAL__COPIED_TO_CLIPBOARD,
  GENERAL__EMAIL,
  GENERAL__FIRST_NAME,
  GENERAL__INVITE,
  GENERAL__LANGUAGE,
  GENERAL__LAST_NAME,
  GENERAL__SELECT,
  GENERAL__WORKERCLASS,
} from 'translations/constants';
import Lang from 'types/Lang';
import { IAddEditEmployee } from 'types/employee';
import { getInputErrorText, getUpDropdownItemsWithIdAsValueFromEnum } from 'utils/helpers';
import { ReactComponent as CopyToClipboard } from '../../../assets/icons/CopyToClipboard.svg';
import AddGeneralEmployeeInvitation from './AddGeneralEmployeeInvitation';
import GeneralEmployeeInvitation from './GeneralEmployeeInvitation';
import {
  EmailStyled,
  InviteEmployeeModalWrapper,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  NameStyled,
  RowContainer,
  RowContainerForContactInfo,
} from './styles';
import { IInviteEmployeeProps } from './types';

const EmployeeInitialState: {
  firstName: string;
  lastName: string;
  contractType: string;
  languageId: string;
  emailAddress: string;
  companyLocationIds: number[];
} = {
  firstName: '',
  lastName: '',
  contractType: '',
  languageId: '',
  emailAddress: '',
  companyLocationIds: [],
};

const InviteEmployeeModal = (props: IInviteEmployeeProps) => {
  const { open, onClose, companyId } = props;
  const [t] = useTranslation();
  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    defaultValues: EmployeeInitialState,
  });

  const dispatch = useAppDispatch();

  const [invitationSent, setInvitationSent] = useState<string>('');
  const [invitationErrorMessage, setInvitationErrorMessage] = useState<string>('');
  const [invitationSuccess, setInvitationSuccess] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);

  const contractTypeOptions: UPDropdownItem[] = useMemo(() => {
    return ContractTypeOptions();
  }, []);

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  const onSave: SubmitHandler<IAddEditEmployee> = async () => {
    const values = getValues();
    const objectToSend = {
      firstname: values.firstName,
      lastname: values.lastName,
      contractType: values.contractType,
      emailAddress: values.emailAddress,
      languageId: toNumber(values.languageId),
      assignedCompanyLocations: values.companyLocationIds?.toString(),
    };

    const response = await inviteEmployeeRequest({ employee: objectToSend, companyId });
    if (response.error) {
      setInvitationErrorMessage(response.error);
      setInvitationSuccess(false);
    } else {
      setInvitationSent(response.link);
      setInvitationSuccess(true);
    }
  };

  const locationsDropdownItems = useAppSelector(locationsDropdownItemsSelector);
  const locations = useMemo(() => locationsDropdownItems, [locationsDropdownItems]);

  useEffect(() => {
    dispatch(getLocationsDropdownItemsAction({ companyId: toNumber(companyId) }));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (locations.length === 1) {
      setValue('companyLocationIds', [locations[0].value]);
      clearErrors('companyLocationIds');
    }
  }, [clearErrors, locations, setValue]);

  const tabs = [
    t(EMPLOYEES__INDIVIDUAL_INVITATION),
    t(EMPLOYEES__ADD_GENERAL_INVITATION),
    t(EMPLOYEES__GENERAL_INVITATION_LIST),
  ];

  return (
    <Backdrop open>
      <InviteEmployeeModalWrapper open={open} onClose={onClose}>
        <ModalContainer>
          <InviteEmployeeTab sections={tabs} onClose={onClose}>
            <SectionContainer>
              {invitationSent && invitationSuccess && (
                <>
                  <ModalHeader>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <ModalTitle> {t(EMPLOYEES__INVITE_EMPLOYEE_SENT)} </ModalTitle>

                      <UPLinkStyled
                        sx={{
                          color: 'rgb(0, 140, 21)',
                          paddingRight: '1rem',
                          paddingLeft: '1rem',
                          paddingTop: '1px',
                          fontWeight: '600',
                          fontSize: '16',
                          cursor: 'pointer',
                        }}
                        onClick={e => {
                          e.preventDefault();
                          handleCopyClick(invitationSent);
                        }}
                        underline="none"
                        small={false}
                      >
                        {t(EMPLOYEES__INVITE_EMPLOYEE_COPY_INVITATION_LINK)}
                      </UPLinkStyled>
                      <CopyToClipboard onClick={() => handleCopyClick(invitationSent)} style={{ cursor: 'pointer' }} />
                      {copied && (
                        <span style={{ paddingLeft: '0.5rem', fontSize: '75%' }}>
                          {t(GENERAL__COPIED_TO_CLIPBOARD)}
                        </span>
                      )}
                    </Box>
                  </ModalHeader>
                  <ModalFooter>
                    <UPButton text={t(GENERAL__CLOSE)} onClick={onClose} outlined />
                  </ModalFooter>
                </>
              )}
              {(!invitationSent || !invitationSuccess) && (
                <Box>
                  <RowContainerForContactInfo>
                    <NameStyled>
                      <Controller
                        name="firstName"
                        control={control}
                        rules={{ required: true, maxLength: 50 }}
                        render={({ field }) => (
                          <UPInput
                            value={field.value}
                            onChange={field.onChange}
                            label={t(GENERAL__FIRST_NAME)}
                            placeholder={t(GENERAL__FIRST_NAME)}
                            required
                            error={!!errors.firstName}
                            helperText={getInputErrorText(errors, 'firstName', undefined, 50)}
                            inputClassName="white-background"
                          />
                        )}
                      />
                    </NameStyled>
                    <NameStyled>
                      <Controller
                        name="lastName"
                        control={control}
                        rules={{ required: true, maxLength: 50 }}
                        render={({ field }) => (
                          <UPInput
                            value={field.value}
                            onChange={field.onChange}
                            label={t(GENERAL__LAST_NAME)}
                            placeholder={t(GENERAL__LAST_NAME)}
                            required
                            error={!!errors.lastName}
                            helperText={getInputErrorText(errors, 'lastName', undefined, 50)}
                            inputClassName="white-background"
                          />
                        )}
                      />
                    </NameStyled>
                    <EmailStyled>
                      <Controller
                        name="emailAddress"
                        control={control}
                        rules={{ required: true, maxLength: 50, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                        render={({ field }) => (
                          <UPInput
                            value={field.value}
                            onChange={field.onChange}
                            label={t(GENERAL__EMAIL)}
                            placeholder={t(GENERAL__EMAIL)}
                            required
                            error={!!errors.emailAddress}
                            helperText={getInputErrorText(errors, 'emailAddress', undefined, 100)}
                            inputClassName="white-background"
                            autoComplete="off"
                          />
                        )}
                      />
                    </EmailStyled>
                  </RowContainerForContactInfo>
                  <RowContainerForContactInfo>
                    <RowContainer width={1 / 2}>
                      <Controller
                        name="companyLocationIds"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value: currentValue } }) => (
                          <UPMultipleSelectionDropdown
                            items={locations}
                            handleChange={event => {
                              const {
                                target: { value },
                              } = event;
                              onChange(value);
                            }}
                            value={currentValue}
                            dropdownLabel={t(EMPLOYEE__COMPANY_LOCATION)}
                            placeholder={
                              locations.length === 0 ? 'No locations available' : t(EMPLOYEE__COMPANY_LOCATION)
                            }
                            disabled={locations.length <= 1}
                            helperText={getInputErrorText(errors, 'companyLocationIds')}
                            error={!!errors.companyLocationIds}
                            required
                          />
                        )}
                      />
                    </RowContainer>
                    <RowContainer width={1 / 4}>
                      <Controller
                        name="contractType"
                        control={control}
                        rules={{ required: true, maxLength: 50 }}
                        render={({ field }) => (
                          <UPDropdown
                            value={field.value || ''}
                            onChange={field.onChange}
                            label={t(GENERAL__WORKERCLASS)}
                            placeholder={t(GENERAL__SELECT)}
                            required
                            error={!!errors.contractType}
                            helperText={getInputErrorText(errors, 'languageId')}
                            className="dropdown--white-background"
                            items={contractTypeOptions}
                          />
                        )}
                      />
                    </RowContainer>
                    <RowContainer width={1 / 4}>
                      <Controller
                        name="languageId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <UPDropdown
                            value={field.value || ''}
                            onChange={field.onChange}
                            label={t(GENERAL__LANGUAGE)}
                            placeholder={t(GENERAL__SELECT)}
                            required
                            error={!!errors.languageId}
                            helperText={getInputErrorText(errors, 'languageId')}
                            className="dropdown--white-background"
                            items={languageOptions}
                          />
                        )}
                      />
                    </RowContainer>
                  </RowContainerForContactInfo>
                  {Boolean(invitationErrorMessage) && (
                    <Box sx={{ padding: '32px', paddingTop: '10px', color: 'red', fontSize: '16px' }}>
                      {invitationErrorMessage}
                    </Box>
                  )}
                  <ModalFooter>
                    <UPButton
                      text={t(GENERAL__INVITE)}
                      onClick={() => handleSubmit(onSave)()}
                      gtmEvent="employees-invite-personal-invitation"
                      disabled={isSubmitting}
                    />
                    <Box sx={{ marginLeft: '12px' }}>
                      <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onClose} outlined />
                    </Box>
                  </ModalFooter>
                </Box>
              )}
            </SectionContainer>
            <AddGeneralEmployeeInvitation companyId={companyId} locations={locations} onClose={onClose} />
            <GeneralEmployeeInvitation companyId={companyId} onClose={onClose} />
          </InviteEmployeeTab>
        </ModalContainer>
      </InviteEmployeeModalWrapper>
    </Backdrop>
  );
};

export default InviteEmployeeModal;
